import {
  inject,
  Injectable
} from '@angular/core';
import {
  MatSnackBar
} from '@angular/material/snack-bar';
import { AlertToastComponent } from '../components/custom-toasts/alert-toastr/alert-toastr.component';
import { ErrorToastComponent } from '../components/custom-toasts/error-toastr/error-toastr.component';
import { SuccessToastComponent } from '../components/custom-toasts/success-toastr/success-toastr.component';

 export type AlertToastProps = {
  title: string,
  description?: string,
  type?: 'warning' | 'info',
  config?:
  {
    duration?: 0 | 2000 | 5000 | 10000,
    verticalPosition?: 'top' | 'bottom',
    horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right',
    panelClass?: 'alert-toast' | 'alert-toast-full',
  },
  button?: true | false,
  buttonText?: string,
  buttonAction?: () => void
}

@Injectable({
  providedIn: 'root'
})
 

export class CustomToastrService {
  public _snackBar = inject(MatSnackBar);

  public openErrorToast(
    title: string,
    description: string,
    type: 'warning' | 'info',
    panelClass: 'error-toast' | 'error-toast-full',
    horizontalPosition: 'start' | 'center' | 'end' | 'left' | 'right',
    verticalPosition: 'top' | 'bottom'
  ) {
    this._snackBar.openFromComponent(ErrorToastComponent,
      {
        horizontalPosition: horizontalPosition,
        verticalPosition: verticalPosition,
        duration: 5000,
        data: { title, description, type },
        panelClass: [panelClass]

      },
    );
  }

  public openSuccessToast(
    description: string,
    panelClass: 'success-toast' | 'success-toast-full',
    horizontalPosition: 'start' | 'center' | 'end' | 'left' | 'right',
    verticalPosition: 'top' | 'bottom'
  ) {
    this._snackBar.openFromComponent(SuccessToastComponent,
      {
        verticalPosition: verticalPosition,
        horizontalPosition: horizontalPosition,
        duration: 5000,
        data: description,
        panelClass: [panelClass]
      },
    );
  }

  public openAlertToast({
    title,
    description,
    type = "info",
    config = {
      duration: 2000,
      horizontalPosition:'left',
      verticalPosition: 'top',
      panelClass:'alert-toast'
    },
    button = true,
    buttonText = 'Warning',
    buttonAction
  }: AlertToastProps) {
    this._snackBar.openFromComponent(AlertToastComponent,
      {
        horizontalPosition: config.horizontalPosition,
        verticalPosition: config.verticalPosition,
        duration: config.duration,
        data: { title, description, type, button, buttonText, buttonAction },
        panelClass: [config.panelClass ?? 'alert-toast']
      },
    );
  }

  public closeToastr() {
    this._snackBar.dismiss();
  }
}
