@for (stage of stages; track $index) {
<span
  class="flex flex-col justify-center items-center mb-4 last:mb-0.5 bg-slate-100 hover:bg-slate-300 hover:cursor-pointer min-w-48 p-4 group rounded"
  [style.border]="stage.stageCompleted ? '1px solid green' : ''"
  (click)="goToStageView(stage)"
>
  @switch (stage.businessCaseStageStatus) { @case ('APPROVED') {
  <img src="assets/approved.svg" class="w-6 h-6" alt="" /> } @case ('REJECTED')
  {
  <img src="assets/rejected.svg" class="w-6 h-6" alt="" />
  } @default { @if (stage.stageActive) {
  <img src="assets/active.svg" class="w-6 h-6" alt="" />
  } @else {
  <img src="assets/untouched.svg" class="w-6 h-6" alt="" />
  } } }
  <p class="font-semibold text-xs mt-4">
    {{ stage.stageName.split('_').join(' ') }}
  </p>
</span>
}
