<div class="w-full flex flex-col">
  <div class="mt-8">
    <div>
      <p class="text-primary-blue text-2xl font-semibold">
        Confirm Rate Card Link
      </p>
      <p class="text-gray-500 text-sm">
        Confirm rate card has been linked in SAP
      </p>
      <div class="flex justify-between gap-4  items-center bg-white py-4">
        <div class="flex gap-4 justify-center">
          <form class="max-w-sm">
            <mat-form-field class="search-bar" appearance="outline">
              <mat-label class="search-bar-label">Search</mat-label>
              <input style="font-size: 12px" [(ngModel)]="searchName" type="text" matInput placeholder="Search"
                name="searchTerm" />
              <mat-icon (click)="searchForSapName()" class="white-icon" matSuffix>search</mat-icon>
            </mat-form-field>
          </form>
        </div>
        <div class="flex-row flex items-center justify-center">
          <button (click)="showActivityLogDialog()" class="underlined-button cursor-pointer">
            <mat-icon>add </mat-icon>
            Add comment
          </button>
        </div>
      </div>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" appearance="outline">

    <div class="grid grid-cols-4 flex flex-col">
      <ng-container matColumnDef="sapId">
        <th mat-header-cell *matHeaderCellDef>SAP ID </th>
        <td mat-cell *matCellDef="let element"> {{element.sapId}} </td>
      </ng-container>

      <ng-container matColumnDef="sapName">
        <th mat-header-cell *matHeaderCellDef>SAP Name </th>
        <td mat-cell *matCellDef="let element"> {{element.sapName}} </td>
      </ng-container>

      <ng-container matColumnDef="buildingId">
        <th mat-header-cell *matHeaderCellDef>Building ID</th>
        <td mat-cell *matCellDef="let element"> {{element.buildingId}} </td>
      </ng-container>

      <ng-container matColumnDef="highLevelDescription">
        <th mat-header-cell *matHeaderCellDef>High level description</th>
        <td mat-cell *matCellDef="let element"> {{element.highLevelDescription}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <ng-container matColumnDef="spaceType">
        <th mat-header-cell *matHeaderCellDef>Space type</th>
        <td mat-cell *matCellDef="let element"> {{element.spaceType}} </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </div>
  </table>
</div>