import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { Router } from '@angular/router';
import { backOfficeRouteKeys } from '@bidvest/shared';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { IBusinessCaseOverview } from '../../interfaces/business-case-overview.interface';

@Component({
  selector: 'bidvest-business-case-stage-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './business-case-stage-indicator.component.html',
  styleUrl: './business-case-stage-indicator.component.scss'
})
export class BusinessCaseStageIndicatorComponent {
  @Input()
  public get stages(): Array<IBusinessCaseStageConfig> {
    return this._stages;
  }

  public set stages(
    value: IBusinessCaseStageConfig | Array<IBusinessCaseStageConfig>
  ) {
    if (Array.isArray(value)) {
      this._stages = [...value];
    } else {
      this._stages = [value];
    }
  }

  @Input()
  public overview: IBusinessCaseOverview | null = null;

  private _stages: Array<IBusinessCaseStageConfig> = [];
  private _router: Router = inject(Router);

  public goToStageView(stage: IBusinessCaseStageConfig): void {
    switch (stage.stageName) {
      case 'SETUP':
        if (stage.canEdit) {
          this._router.navigateByUrl(
            `${backOfficeRouteKeys['BUSINESS_CASE']}/${this.overview?.id}`
          );
        } else if (stage.canRead) {
          // read only view
        }
        break;
      case 'COST_SOFT_SERVICES':
        if (stage.canEdit) {
          this._router.navigateByUrl(
            `${backOfficeRouteKeys['ADD_SOFT_SERVICES']}/${this.overview?.id}`
          );
        } else if (stage.canRead) {
          this._router.navigateByUrl(
            `${backOfficeRouteKeys['READ_SOFT_SERVICES']}/${this.overview?.id}`
          );
        }
        break;
      case 'COST_ENGINEERING_SERVICES':
        if (stage.canEdit) {
          this._router.navigateByUrl(
            `${backOfficeRouteKeys['COST_ENGINEERING_SERVICES']}`
          );
        } else if (stage.canRead) {
        }
        break;
      case 'VERIFY_ASSET_LIST':
        if (stage.canEdit) {
          this._router.navigateByUrl(
            `${backOfficeRouteKeys['VERIFY_ASSET_LIST_VERIFICATION']}`
          );
        } else if (stage.canRead) {
        }
        break;
      case 'GATHER_ASSET_PRICE':
        this._router.navigateByUrl(`${backOfficeRouteKeys['']}`);
        if (stage.canEdit) {
          this._router.navigateByUrl(
            `${backOfficeRouteKeys['GATHER_ASSET_PRICE']}`
          );
        } else if (stage.canRead) {
        }
        break;
      case 'PROCUREMENT_TO_PRICE':
        console.log('Still need to show what this is');
        break;
      case 'PREPARE_ENGINEERING_QUOTE':
        this._router.navigateByUrl(
          `${backOfficeRouteKeys['PREPARE_ENGINEERING_QUOTE']}`
        );
        break;
      case 'ENGINEERING_REVIEW':
        this._router.navigateByUrl(
          `${backOfficeRouteKeys['ENGINEERING_REVIEW']}`
        );
        break;
      case 'GOVERNANCE_REVIEW':
        this._router.navigateByUrl(
          `${backOfficeRouteKeys['PREPARE_ENGINEERING_QUOTE']}`
        );
        break;
      case 'LEGAL_REVIEW':
        this._router.navigateByUrl(`${backOfficeRouteKeys['LEGAL_REVIEW']}`);
        break;
      case 'CUSTOMER_FINANCE_REVIEW':
        this._router.navigateByUrl(
          `${backOfficeRouteKeys['CUSTOMER_FINANCE_REVIEW']}/${this.overview?.id}`
        );
        break;
      case 'CUSTOMER_ENGINEERING_REVIEW':
        this._router.navigateByUrl(
          `${backOfficeRouteKeys['CUSTOMER_ENGINEERING_REVIEW']}`
        );
        break;
      case 'CUSTOMER_FINAL_REVIEW':
        console.log('Still need to show what this is');
        break;
      case 'GENERATE_CHANGE_NOTE':
        console.log('Still need to show what this is');
        break;
      default:
        break;
    }
  }
}
