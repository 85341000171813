import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { IEngineeringTableData } from '../../models/enigneering-table-data';
import { RateCardsService } from '../../services/rate-cards.service';
import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BusinessCaseActivityLogComponent } from '../../components/business-case-activity-log/business-case-activity-log.component';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';


@Component({
  selector: 'bidvest-adding-rate-card-link-table',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    FormsModule
  ],
  templateUrl: './confirm-rate-card-link.component.html',
  styleUrl: './confirm-rate-card-link.component.scss'
})
export class ConfirmRateCardTableComponent implements OnInit {
  public dataSource: IEngineeringTableData[] | undefined;
  public businessCase: IBusinessCaseDetails | null = null;
  public searchName: string = '';

  constructor(
    private readonly _rateCardService: RateCardsService,
    private readonly _overlay: Overlay
  ) { }

  public displayedColumns: string[] =
    ['sapId', 'sapName', 'buildingId', 'highLevelDescription', 'description', 'spaceType', 'amount', 'status'];

  public async ngOnInit(): Promise<void> {
    this.dataSource = await this._rateCardService.getSapRateCards();
  }

  public searchForSapName() {
    if (this.dataSource) {
      const nameSearched = this.dataSource.filter(
        enteredName =>
          enteredName.sapName.includes(this.searchName),
      )
      this.dataSource = nameSearched;
    }
  }

  public showActivityLogDialog(): void {
    const overlayRef = this._overlay.create({
      width: '450px',
      height: '600px',
      backdropClass: 'bg-slate-400/50',
      panelClass: ['overflow-y-auto', 'rounded-lg'],
      hasBackdrop: true,
      positionStrategy: new GlobalPositionStrategy()
        .bottom('20px')
        .right('15px'),
      scrollStrategy: this._overlay.scrollStrategies.block()
    });
    const activityLogPortal = new ComponentPortal(
      BusinessCaseActivityLogComponent
    );
    const componentRef = overlayRef.attach(activityLogPortal);

    componentRef.setInput('businessCaseId', this.businessCase?.id as number);
    componentRef.instance.overlayRef = overlayRef;
  }

}
