<form [formGroup]="form" class="mx-10 mt-6">
  <h4 class="font-semibold text-primary-blue text-xl px-8 pb-8">
    Notifications
  </h4>
  <div class="flex flex-col items-center w-full mb-28">
    <div class="flex flex-row justify-between w-full px-8 py-4">
      <p class="text-xl ">Comments
      </p>
      <mat-slide-toggle name="comments" color="primary" formControlName="comments"></mat-slide-toggle>
    </div>
    <div class="flex flex-row justify-between w-full px-8 py-4">
      <p class="text-xl ">Alerts
      </p>
      <mat-slide-toggle name="alerts" color="primary" formControlName="alerts"></mat-slide-toggle>
    </div>
    <div class="flex flex-row justify-between w-full px-8 py-4">
      <p class="text-xl ">Label
      </p>
      <mat-slide-toggle name="label" color="primary" formControlName="label"></mat-slide-toggle>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="mt-10">
    <button mat-flat-button color="accent" [disabled]="disableButton()" (click)="toggleNotificationsSettings()"
      class="w-full">
      Save
    </button>
  </div>
</form>