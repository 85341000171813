import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { IProfileNotifications } from "../models/profile-notifications";
import { firstValueFrom, Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
  })

export class ProfileSettingsService {
    private httpClient: HttpClient = inject(HttpClient);
    private _notificationsMockData = 'assets/dummy-data/profile-notifications-mock.json'
  
    public updateNotificationsSettings(notificationsdata: IProfileNotifications): Observable<IProfileNotifications> {
    return this.httpClient.put<IProfileNotifications>(`${this._notificationsMockData}`, notificationsdata);
    }

    public currentNotificationsSettings(): Promise<IProfileNotifications> {
        return firstValueFrom(this.httpClient.get<IProfileNotifications>(`${this._notificationsMockData}`));
        }

}