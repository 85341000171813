<div class="overlay-container">
  <div mat-dialog-title>
    <h2 class="font-semibold text-primary-blue text-2xl pl-4">
      Replace Asset
    </h2>
  </div>
  <div mat-dialog-content class="mt-4">
    <form [formGroup]="form" class="mt-4">
      <mat-form-field appearance="outline" appearance="outline" class="w-full mb-4">
        <mat-label>Category</mat-label>
        <input type="text" matInput placeholder="Category" formControlName="category" />
      </mat-form-field>

      <mat-form-field appearance="outline" appearance="outline" class="w-full mb-6">
        <mat-label>Sub-category</mat-label>
        <input type="text" matInput placeholder="Sub-category" formControlName="subCategory" />
      </mat-form-field>

      <mat-divider></mat-divider>

        <mat-form-field appearance="outline" appearance="outline" class="w-full mb-4 mt-10">
          <mat-label>SAP ID</mat-label>
          <input type="text" matInput placeholder="SAP ID" formControlName="sapId" />
        </mat-form-field>


        <mat-form-field appearance="outline" appearance="outline" class="w-full mb-4">
          <mat-label>SAP name</mat-label>
          <input type="text" matInput placeholder="SAP name" formControlName="sapName" />
        </mat-form-field>

        <mat-form-field appearance="outline" appearance="outline" class="w-full mb-4">
          <mat-label>High Level Description</mat-label>
          <input type="text" matInput placeholder="High Level Description" formControlName="highLevelDescription" />
        </mat-form-field>
        <div class="flex flex-row mb-4">
          <button type="button" mat-flat-button color="accent" (click)="addNewRateCard()" [disabled]="!form.valid"
            class="w-full">
            Replace
          </button>
        </div>
    </form>
  </div>
</div>