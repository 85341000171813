<div class="mb-4">
  <h4 class="text-primary-blue font-bold text-2xl">Staff</h4>
  <p class="text-gray-400 text-sm">
  </p>
</div>
<div class="flex justify-between items-center py-4">
  <div class="flex flex-row justify-start">
    <mat-form-field class="mr-2" appearance="outline">
      <mat-label>Search</mat-label>
      <input
        style="font-size: 12px"
        type="text"
        matInput
        placeholder="Search"
        name="searchTerm"
        [(ngModel)]="searchTerm"
      />
      <mat-icon matSuffix (click)="triggerChange()">search</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Filter</mat-label>
      <mat-select [(ngModel)]="role">
        <mat-option [value]="" (click)="applyFilter('')">All</mat-option>
        @for (categories of filterCategoriesKeys; track categories) {
        <mat-option [value]="categories" (click)="applyFilter(categories)">{{categories}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div class="flex-row flex items-center justify-center">
    <button mat-flat-button color="accent" (click)="showNewUserDialog()">
      <mat-icon>add </mat-icon>
      New staff
    </button>
  </div>
</div>
<bidvest-staff-table
  [data]="users$ | async"
  (actions)="handleStaffAction($event)"
></bidvest-staff-table>
