
export enum StaffRolesEnum {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    STAFF = 'STAFF',
    CUSTOMER = 'CUSTOMER',
    STUDENT = 'STUDENT',
    APPLICANT = 'APPLICANT',
    PROCUREMENT = 'PROCUREMENT',
    GENERAL_MANAGER = 'GENERAL_MANAGER',
    ENGINEERING_SERVICE_MANAGER = 'ENGINEERING_SERVICE_MANAGER',
    PRINCIPLE_ENGINEER = 'PRINCIPLE_ENGINEER',
    SITE_PROJECT_MANAGER = 'SITE_PROJECT_MANAGER',
    GOVERNANCE = 'GOVERNANCE',
    LEGAL = 'LEGAL',
    BIDVEST_FINANCIAL_MANAGEMENT = 'BIDVEST_FINANCIAL_MANAGEMENT',
    ABSA_FINANCIAL_MANAGEMENT_VP = 'ABSA_FINANCIAL_MANAGEMENT_VP',
    ABSA_ENGINEERING_VP = 'ABSA_ENGINEERING_VP',
    CHIEF_COMMERCIAL_OFFICER = 'CHIEF_COMMERCIAL_OFFICER'
}

