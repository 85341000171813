<div class="w-full h-full flex flex-col justify-start p-6">
    <div class="flex max-w-lg mx-auto items-center">
        <div class="overlay-container">
            <div class="flex flex-row justify-between">
                <p class="font-normal text-blue-800 text-2xl mb-10">
                    Asset Seen
                </p>
                <mat-icon class="text-gray-400 cursor-pointer" (click)="closeAssetList()">close</mat-icon>
            </div>
            <form [formGroup]="form" class="px-4">
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>SAP ID</mat-label>
                    <input type="text" matInput formControlName="sapId" />
                </mat-form-field>
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>SAP name</mat-label>
                    <input type="text" matInput formControlName="sapName" />
                </mat-form-field>
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Description</mat-label>
                    <input type="text" matInput formControlName="description" />
                </mat-form-field>

                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Action status</mat-label>
                    <mat-select formControlName="actionStatus">
                        @for (actions of actionStatusOptions; track actions) {
                        <mat-option [value]="actions">{{actions}}</mat-option>
                        }

                    </mat-select>
                </mat-form-field>
                <div class="pt-4">
                    <mat-divider></mat-divider>
                </div>
                <div class="pb-4"></div>
                <div class="flex justify-between items-center">
                    <button mat-flat-button color="accent" [disabled]="!form.valid" 
                        class="bg-orange-500 rounded-lg px-6 py-2 flex flex-row justify-center items-center w-full text-white font-bold">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>