import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { UserPermission } from '../../interfaces/user-permission.interface';
import { Subject, takeUntil } from 'rxjs';
import { IUserPermissions } from '../../models/user-permissions';

@Component({
  selector: 'bidvest-permissions-selector',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule],
  templateUrl: './permissions-selector.component.html',
  styleUrl: './permissions-selector.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PermissionsSelectorComponent
    }
  ]
})
export class PermissionsSelectorComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input()
  public get feature(): string {
    return this._feature.split('_').join(' ');
  }

  public set feature(value: string) {
    this._feature = value;
  }

  @Input()
  public userPermissions: UserPermission | undefined;
  public selectedPermissions!: UserPermission;
  public permissionsForm!: FormGroup;
  public checkAllPermissions: boolean = false;
  public touched: boolean = false;
  public disabled: boolean = false;

  public onChange: Function = (permissions: UserPermission) => {};
  public onTouched: Function = () => {};

  private _fb: FormBuilder = inject(FormBuilder);
  private _destroy$: Subject<void> = new Subject();
  private _feature!: string;

  public ngOnInit(): void {
    this.permissionsForm = this._fb.group({
      read: [this.userPermissions?.read, Validators.required],
      write: [this.userPermissions?.write, Validators.required],
      update: [this.userPermissions?.update, Validators.required],
      delete: [this.userPermissions?.delete, Validators.required]
    });
    if(this.userPermissions?.read && this.userPermissions?.write && this.userPermissions?.update && this.userPermissions?.delete) {
      this.selectAllPermissions();
    }
    this.permissionsForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (entries: UserPermission) => {
          this.markAsTouched();
          if (!this.disabled) {
            this.selectedPermissions = {
              ...this.selectedPermissions,
              ...entries
            };
            this.onChange(this.selectedPermissions);
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public writeValue(permissions: UserPermission) {
    this.selectedPermissions = permissions;
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public selectAllPermissions(): void {
    this.markAsTouched();

    if (this.checkAllPermissions) {
      this.permissionsForm.patchValue({
        read: false,
        write: false,
        update: false,
        delete: false
      });
      this.selectedPermissions = {
        read: false,
        write: false,
        update: false,
        delete: false,
        permissionFeature: this._feature,
        preApprove: false
      };
    } else {
      this.permissionsForm.patchValue({
        read: true,
        write: true,
        update: true,
        delete: true
      });
      this.selectedPermissions = {
        read: true,
        write: true,
        update: true,
        delete: true,
        permissionFeature: this._feature,
        preApprove: false
      };
    }

    this.onChange(this.selectedPermissions);
    this.checkAllPermissions = !this.checkAllPermissions;
  }
}
