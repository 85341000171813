import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { IFilters } from '../../../models/filters';
import { RateCardTableComponent } from '../../components/rate-card-table/rate-card-table.component';
import { RouterLink } from '@angular/router';
import { backOfficeRouteKeys } from 'libs/shared/src/lib/shared/routes/backoffice.routes';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CreateRateCardOverlayComponent } from '../../components/overlays/create-rate-card-overlay/create-rate-card-overlay.component';
import { UpdateRateCardOverlayComponent } from '../../components/overlays/update-rate-card-overlay/update-rate-card-overlay.component';
import { BusinessCaseHeaderComponent } from '@bidvest/shared';
import { RateCardsService } from '../../services/rate-cards.service';

@Component({
  selector: 'bidvest-rate-card-table-layout',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    RateCardTableComponent,
    RouterLink,
    FormsModule,
    BusinessCaseHeaderComponent,
    MatDialogModule
  ],
  templateUrl: './rate-card-layout.component.html',
  styleUrl: './rate-card-layout.component.scss',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
        appearance: 'outline'
      }
    }
  ]
})
export class RateCardLayoutComponent {
  public routes: { [key: string]: string } = backOfficeRouteKeys;

  constructor(
    private readonly _dialog: MatDialog,
    public rateCardService: RateCardsService
  ) { }

  showNewRateCardDialog(): void {
    this._dialog.open(CreateRateCardOverlayComponent, {
      width: '800px',
      height: '650px'
    });
  }

  handleRateCardId(event: number): void {
    this._dialog.open(UpdateRateCardOverlayComponent, {
      width: '570px',
      height: '710px',
      data: {
        rateCardId: event
      }
    }
    )
  }
}

