import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bidvest-confirm-business-case-deletion-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatFormField,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatIconModule
  ],
  templateUrl: './confirm-business-case deletion-dialog.component.html',
  styleUrl: './confirm-business-case deletion-dialog.component.scss'
})
export class ConfirmBusinessCaseDeletionDialogComponent {
  public businessCaseRefNo!: string;
  public readonly data = inject<{ refNo: string}>(MAT_DIALOG_DATA);
  public allowDelete: boolean = false;


  private readonly _dialogRef: MatDialogRef<ConfirmBusinessCaseDeletionDialogComponent> = inject(MatDialogRef<ConfirmBusinessCaseDeletionDialogComponent>);

  public deleteBusinessCase(): void {
    this._dialogRef.close(true);
  }


}
