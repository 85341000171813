import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { User } from '../../../interfaces/user.interface';
import { StaffTableAction } from '../../../interfaces/staff-table-action.interface';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'bidvest-staff-table',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatTooltipModule
  ],
  templateUrl: './staff-table.component.html',
  styleUrl: './staff-table.component.scss'
})
export class StaffTableComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  @Output()
  public actions: EventEmitter<StaffTableAction> = new EventEmitter();

  @Input()
  public get data(): Array<User> {
    return this._data;
  }

  public set data(values: Array<User> | null) {
    if (values && values.length > 0) {
      this._data = [...values];
    }
  }

  public readonly displayedColumns: string[] = [
    'fullName',
    'role',
    'email',
    'activated',
    'verified',
    'actions'
  ];

  public readonly dataSource = new MatTableDataSource<User>([]);

  public readonly selection = new SelectionModel<User>(true, []);

  private _data: Array<User> = [];

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = changes['data'].currentValue;
  }

  public isAllSelected(): boolean {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  public editStaff(user: User): void {
    this.actions.next({
      actionType: 'EDIT',
      userId: user.id,
      user: user
    });
  }

  public activateUser(user: User): void {
    this.actions.next({
      actionType: 'ACTIVATE',
      userId: user.id,
      user: user
    });
  }

  public deactivateUser(user: User): void {
    this.actions.next({
      actionType: 'DEACTIVATE',
      userId: user.id,
      user: user
    });
  }
}
