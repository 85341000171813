<div class="w-full bg-slate-200 px-4 flex flex-col justify-start">
  <bidvest-business-case-header>
    <span ngProjectAs="business-case-header-back-btn">
      <button mat-icon-button [routerLink]="routes['BUSINESS_CASES']">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span ngProjectAs="business-case-header-title">
      <p class="heading-text">Business Case Name</p>
    </span>
    <span ngProjectAs="business-case-header-number">{{ businessCase?.referenceNumber }}</span>
    <span ngProjectAs="business-case-header-actions">
      <button class="submission-button">Submit the asset proposal</button>
    </span>
  </bidvest-business-case-header>

  <div>
    <div class="flex flex-col">
      <div class="flex flex-row justify-between">
        <div class="bg-white w-full rounded-md px-8 pt-6 min-h-[109px] items-center">
          <p class="heading-text">Asset list verification</p>
          <p class="sub-heading-text">
            Confirm existing asset list and add assets that are not on the list but on the site
          </p>
        </div>
      </div>

      <div class="row-container mt-4 rounded-md">
        <div class="grid grid-cols-1 mb-4">
          <bidvest-verification-table></bidvest-verification-table>
        </div>
      </div>
    </div>
  </div>
</div>
