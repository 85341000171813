<div
  mat-dialog-content
  class="w-full min-h-full h-full overflow-y-auto bg-slate-200 p-4 flex flex-col justify-start"
>
  @if (businessCaseSetup !== undefined && businessCaseSetup !== null) {
  <bidvest-business-case-header>
    <span ngProjectAs="business-case-header-back-btn">
      <button mat-icon-button mat-dialog-close>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span ngProjectAs="business-case-header-title">Test</span>
    <span ngProjectAs="business-case-header-number">{{
      businessCaseSetup.referenceNumber
    }}</span>
    <span ngProjectAs="business-case-header-actions">
      <button
        mat-stroked-button
        class="mr-2"
        color="accent"
        (click)="updateBusinessCase()"
      >
        Save as draft
      </button>
      <button mat-button (click)="submitBusinessCaseForReview()">
        Create business case
      </button>
    </span>
  </bidvest-business-case-header>

  <div class="rounded p-6 bg-white">
    <bidvest-new-business-case-form
      [collaborators]="businessCaseSetup.accounts"
      [stages]="businessCaseStages"
      [businessCaseId]="businessCaseSetup.id"
      #newBusinessCase="newBusinessCase"
    ></bidvest-new-business-case-form>
  </div>
  } @else {
  <bidvest-business-case-header>
    <span ngProjectAs="business-case-header-back-btn">
      <button mat-icon-button mat-dialog-close>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span ngProjectAs="business-case-header-title">Test</span>
  </bidvest-business-case-header>
  <form
    [formGroup]="businessCaseTypeForm"
    class="w-full h-full flex flex-col justify-start items-center pt-32 bg-white"
  >
    <div class="w-[500px]">
      <p class="font-semibold mb-6">Please select a Business Case Type</p>

      <mat-form-field appearance="outline" floatLabel="always" class="w-full">
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row justify-center"
          >Select Business Case Type
          <mat-icon class="ml-2">info</mat-icon></mat-label
        >
        <mat-select formControlName="businessCaseType">
          @for (type of businessCaseTypes; track type) {
          <mat-option [value]="type">{{
            type.trim().split('_').join(' ')
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <button
        mat-flat-button
        color="accent"
        class="w-full"
        (click)="initializeBusinessCase()"
      >
        Continue
      </button>
    </div>
  </form>
  }
</div>
