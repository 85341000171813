import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { StaffTableComponent } from '../../components/tables/staff-table/staff-table.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewStaffOverlayComponent } from '../../components/overlays/new-staff-overlay/new-staff-overlay.component';
import { UserService } from '../../services/user.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../../interfaces/user.interface';
import { StaffTableAction } from '../../interfaces/staff-table-action.interface';
import { ToastrService } from 'ngx-toastr';
import { ManageStaffOverlayComponent } from '../../components/overlays/manage-staff-details-overlay/manage-staff-details-overlay.component';
import { StaffRolesEnum } from '../../enums/staff-roles.enum';

@Component({
  selector: 'bidvest-staff',
  standalone: true,
  imports: [
    StaffTableComponent,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    AsyncPipe
  ],
  templateUrl: './staff.component.html',
  styleUrl: './staff.component.scss'
})
export class StaffComponent implements OnInit, OnDestroy {
  public users$!: Observable<Array<User>>;


  private _dialog: MatDialog = inject(MatDialog);
  private _userService: UserService = inject(UserService);
  private _toastr: ToastrService = inject(ToastrService);
  public currentPage: number = 1;
  public size: number = 10;
  public sortBy: string = '';
  public sortDirection: string = '';
  public searchTerm: string = '';
  public role: string = '';
  public activated: boolean | null = null;

  private _users: Array<User> = [];
  private _users$: BehaviorSubject<Array<User>> = new BehaviorSubject(
    this._users
  );
  private _subscriptions: Array<Subscription> = [];
  public filterCategoriesKeys = Object.keys(StaffRolesEnum);


  public ngOnInit(): void {
    this.users$ = this._users$.asObservable();
    this.fetchAllUsers();
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public fetchAllUsers(): void {
    const subscription = this._userService
      .getAllUsers({
        currentPage: this.currentPage,
        size: this.size,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        searchTerm: this.searchTerm,
        role: this.role,
        activated: this.activated
      })
      .subscribe((res) => {
        this._users = [...res.accounts];
        this._users$.next(this._users);
        return res;
      });

    this._subscriptions.push(subscription);
  }

  public triggerChange() {
    this.fetchAllUsers();
  }

  public showNewUserDialog(): void {
    const dialogRef: MatDialogRef<NewStaffOverlayComponent> = this._dialog.open(
      NewStaffOverlayComponent,
      {
        width: '800px',
        height: '650px'
      }
    );

    const subscription = dialogRef.afterClosed().subscribe((value: boolean) => {
      if (value) {
        this.fetchAllUsers();
      }
    });

    this._subscriptions.push(subscription);
  }

  public handleStaffAction(event: StaffTableAction): void {
    if (event.actionType === 'EDIT') {
      const dialogRef: MatDialogRef<ManageStaffOverlayComponent> =
        this._dialog.open(ManageStaffOverlayComponent, {
          width: '800px',
          height: '650px',
          data: {
            user: event.user
          }
        });

      const subscription = dialogRef
        .afterClosed()
        .subscribe(() => {
          this.fetchAllUsers();
        });

      this._subscriptions.push(subscription);
    } else if (event.actionType === 'DEACTIVATE') {
      this._userService.deactivateUser(event.userId).subscribe({
        next: (_) => {
          this._toastr.success('User has been successfully deactivated!');
          this.fetchAllUsers();
        }
      });
    } else if (event.actionType === 'ACTIVATE') {
      this._userService.activateUser(event.userId).subscribe({
        next: (_) => {
          this._toastr.success('User has been successfully activated!');
          this.fetchAllUsers();
        }
      });
    }
  }

  public applyFilter(event: string) {
    this.role = event;
    this.fetchAllUsers();
  }
}
