<div class="w-full flex flex-row justify-between items-center p-4">
  <h4 class="font-semibold text-red-500 inline-block">Delay Notice!!</h4>
</div>
<div matDialogContent>
  <div class="flex flex-col">
    <p class="text-red-500 mb-2">
      A delay notice has been flagged for {{ data.stageName }}
    </p>

    <div
      class="h-60 w-full flex flex-col justify-center items-center bg-red-100 border-red-200 border-dashed bprder-4"
    >
      <h4 class="font-semibold text-red-500">
        Duration: {{ data.delayDayCount }} days
      </h4>
    </div>
  </div>
</div>
<div class="grid grid-cols-2 gap-4 px-4 pb-6">
  <button mat-stroked-button color="accent" (click)="closeDialog('reason')">
    Give Reason
  </button>
  <button mat-flat-button color="accent" (click)="closeDialog('update')">
    Update
  </button>
</div>
