import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Inject
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RateCardsService } from '../../../services/rate-cards.service';
import { RateCard } from '../../../models/rate-card.interface';
import { Category } from '../../../enums/category.enum';
import { RateCardGroup } from '../../../enums/rate-card-group.enum';
import { SubCategory } from '../../../enums/sub-category.enum';
import { UnitsOfMeasurement } from '../../../enums/units-of-measurement.enum';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

interface ICategories {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'bidvest-rate-card-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDividerModule
  ],
  templateUrl: './update-rate-card-overlay.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './update-rate-card-overlay.component.scss'
})
export class UpdateRateCardOverlayComponent implements OnInit {
  public form: FormGroup | undefined;

  public unitsOfMeaurement: ICategories[] = [
    { value: 'Each', viewValue: UnitsOfMeasurement.EACH },
    { value: 'Bale', viewValue: UnitsOfMeasurement.BALE },
    { value: 'Hour', viewValue: UnitsOfMeasurement.HOUR },
    { value: 'Km', viewValue: UnitsOfMeasurement.KM },
    { value: 'Month', viewValue: UnitsOfMeasurement.MONTH },
    { value: 'Sqm', viewValue: UnitsOfMeasurement.SQM },
    { value: 'Week', viewValue: UnitsOfMeasurement.WEEK },
  ];

  public categories: ICategories[] = [
    { value: 'Cleaning', viewValue: Category.CLEANING },
    { value: 'Hygiene', viewValue: Category.HYGIENE },
    { value: 'Shredding', viewValue: Category.SHREDDING },
    { value: 'Staff', viewValue: Category.STAFF },
  ]

  public subCategories: ICategories[] = [
    { value: 'Cleaner', viewValue: SubCategory.CLEANER },
    { value: 'Supervisor', viewValue: SubCategory.SUPERVISOR },
    { value: 'Supply Deliver', viewValue: SubCategory.SUPPLY_DELIVER },

  ]

  public rateCardGroup: ICategories[] = [
    { value: 'Engineering services', viewValue: RateCardGroup.ENGINEERING_SERVICES },
    { value: 'Soft services', viewValue: RateCardGroup.SOFT_SERVICES },
    { value: 'Other', viewValue: RateCardGroup.OTHER }
  ]
  public currentData!: RateCard;
  
  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { rateCardId: number },
    private readonly _fb: FormBuilder,
    private readonly _ratecardService: RateCardsService,
    private readonly _loader: NgxUiLoaderService,
    private readonly _toast: ToastrService,
    private readonly _dialogRef: MatDialogRef<UpdateRateCardOverlayComponent>,
  ) {

    this.form = this._fb.group({ //Initializes the form
      serviceName: ['', [Validators.required]],
      serviceDescription: ['', [Validators.required]],
      category: ['', [Validators.required]],
      subCategory: ['', [Validators.required]],
      rateCardGroup: ['', [Validators.required]],
      code: ['01234'],
      unitCost: ['', [Validators.required]],
      unitOfMeasure: ['', [Validators.required]],
      notes: ['', [Validators.required]],
      vendorName: ['', [Validators.required]],
      validTillDate: ['']
    });
  }

  public async viewSelectedRateCard(): Promise<void> {
    this.currentData = await this._ratecardService.getSingleRateCard(this.data.rateCardId);    
  }

  public updateRateCard() {
    try {
      this._loader.start();
      if (this.form?.status === 'VALID') {
        this._ratecardService.updateRateCard({ id: this.currentData.id, ...this.form?.value });
        this._ratecardService.triggerRateCardTableRefresh();
      }
      this._dialogRef.close();
      this._loader.stop();
      this._toast.success('The rate card has been successfully updated');
    } catch {
      this._toast.error('Error occurred while updating rate card');

    }
  }

  public async ngOnInit(): Promise<void> {
    await this.viewSelectedRateCard();
    this.populateForm();
  }

  public populateForm(): void {
    if (this.form && this.currentData) {
      this.form.patchValue({
        id: this.currentData.id,
        serviceName: this.currentData.serviceName,
        serviceDescription: this.currentData.serviceDescription,
        category: this.currentData.category,
        subCategory: this.currentData.subCategory,
        rateCardGroup: this.currentData.rateCardGroup,
        unitCost: this.currentData.unitCost,
        unitOfMeasure: this.currentData.unitOfMeasure,
        notes: this.currentData.notes,
        vendorName: this.currentData.vendorName,
        validTillDate: this.currentData.validTillDate
      });
    }
  }

  public closeOverlay() {
    this._dialogRef.close();
  }
}
