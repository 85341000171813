<div class="flex flex-row justify-between">
  <div class="bg-transparent text-white flex flex-col">
    <div class="flex flex-row">
      <img *ngIf='data.type === "info"' height="=20" width="20" src="assets/Infosvg.svg" />
      <img *ngIf='data.type === "warning"' height="=20" width="20" src="assets/Alert.svg" />
      <div class="p-2">
        <p class="mr-2 text-sm text-semibold">{{ data.title }}</p>
      </div>
    </div>
    <div>
      <p class="text-sm text-semibold">
        {{ data.description }}
      </p>
    </div>
    <div *ngIf="data.button">
      <button mat-flat-button  (click)="data.buttonAction()">
        <p class="button-text-style">
          {{ data.buttonText }}
        </p>
      </button>
    </div>
  </div>
  <div>
    <mat-icon class="cursor-pointer"  (click)="closeOnClick()">close</mat-icon>
  </div>
</div>