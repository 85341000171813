import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IEngineeringServciesTabData } from '../../../models/engineering-tab-data';
import { EngineeringServiceTableService } from '../../../services/engineering-table.service';

@Component({
  selector: 'engineering-services-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './engineering-services-overview-tab.component.html',
  styleUrl: './engineering-services-overview-tab.component.scss'
})
export class EngineeringServicesTabComponent implements OnInit {
  public dataSource: IEngineeringServciesTabData[] | undefined;

  constructor(
    private _engineeringTableService: EngineeringServiceTableService
  ) { }
  displayedColumns: string[] =
    ['category', 'gmpBefore', 'removedAssets', 'revisedGmpOne', 'reactiveOne',
      'firstTimeInstallation', 'reactiveTwo', 'revisedGmpTwo',
      'removalOfReactiveCharge', 'additionOfReactiveCharge',
      'finalRevisedGmp', 'gmpNet'
    ];

  public hardcodedValues: Array<string> = [
    'Electrical planned',
    'Mechanical planned',
    'Electrical reactive (in warranty)',
    '',
    'Electrical reactive (Out of warranty)',
    'Mechanical reactive (Out of warranty)'
  ];

  public getHardcodedValue(index: number): string {
    return this.hardcodedValues[index];
  }

  public async getEngineeringTableData() {
    this.dataSource = await this._engineeringTableService.getEngineeringData();  
  }

  public ngOnInit() {
     this.getEngineeringTableData();
   }
}
