<div class="py-4">
  <p class="font-semibold text-primary-blue py-4 text-2xl">Soft Services Overview</p>
</div>
<table mat-table [dataSource]="dataSource" appearance="outline">
  <ng-container matColumnDef="serviceName">
    <th mat-header-cell *matHeaderCellDef>Service name</th>
    <td mat-cell *matCellDef="let element">{{ element.serviceName }}</td>
    <td mat-footer-cell *matFooterCellDef> Total </td>
  </ng-container>

  <ng-container matColumnDef="unitCost">
    <th mat-header-cell *matHeaderCellDef>Unit cost</th>
    <td mat-cell *matCellDef="let element">
      <div class="flex flex-row">
        <p class="text-primary-blue">
          R
        </p>
        {{ element.unitCost }}
      </div>

    </td>
    <td mat-footer-cell *matFooterCellDef>R {{ softServiceData?.totalSoftServicesCost }} </td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef>Quantity</th>
    <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
    <td mat-footer-cell *matFooterCellDef>{{ softServiceData?.totalItems }}</td>
  </ng-container>

  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

  <mat-paginator aria-label="Select page"> </mat-paginator>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
