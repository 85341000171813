import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'bidvest-delay-notice-dialog',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogContent, MatButtonModule],
  templateUrl: './delay-notice-dialog.component.html',
  styleUrl: './delay-notice-dialog.component.scss'
})
export class DelayNoticeDialogComponent {
  public readonly data = inject<{ delayDayCount: string; stageName: string }>(
    MAT_DIALOG_DATA
  );
  private readonly _dialogRef: MatDialogRef<DelayNoticeDialogComponent> =
    inject(MatDialogRef);

  closeDialog(actionType: string): void {
    this._dialogRef.close(actionType);
  }
}
