<div class="w-full h-full flex flex-col justify-center items-center p-6 bg-gray-500">
    <div class="overlay-container">
        <h2 class="font-semibold text-red-400 text-2xl p-4">
            Soft Delete User
        </h2>
        <mat-divider></mat-divider>
        <div class="p-8">
            <p class="text-sm text-red-300">
                'user name' is still connected to stages
            </p>
            <p>
                This user is connected to stages listed below, a new stage owner has to be selected
            </p>
        </div>
        <form [formGroup]="form">
            <div class="grid grid-cols-2 p-8 gap-4">
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Stage title</mat-label>
                    <mat-select>
                        @for (types of types; track types) {
                        <mat-option [value]="types.value">{{types.viewValue}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Reviewer</mat-label>
                    <mat-select>
                        @for (types of types; track types) {
                        <mat-option [value]="types.value">{{types.viewValue}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="mb-6"></div>
            <div class="flex justify-between items-center">
                <button
                    class="bg-orange-500 rounded-lg px-6 py-2 flex flex-row justify-center items-center w-full text-white font-bold">
                    Delete
                </button>
            </div>
        </form>
    </div>
</div>