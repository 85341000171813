<div>
  <bidvest-business-case-header *ngIf="businessCaseId">
    <span
      ngProjectAs="business-case-header-back-btn"
      [routerLink]="routes['BUSINESS_CASE'] + '/' + businessCaseId"
    >
      <button mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span
      ngProjectAs="business-case-header-title"
      [matTooltip]="businessCase?.businessCaseName!"
    >
      {{ businessCase?.businessCaseName | titlecase }}
    </span>
    <span ngProjectAs="business-case-header-number">
      {{ businessCase?.referenceNumber }}
    </span>
    <span ngProjectAs="business-case-header-actions">
      @if (businessCase?.activeStageName === 'COST_SOFT_SERVICES' && businessCase?.activeStageStatus === 'IN_REVIEW' && isReviewer()){
        <button
          class="underlined-button"
          (click)="showActivityLogDialog()"
        >
        <mat-icon class="pt-1.5">add</mat-icon>
          Add comment
        </button>
        <button
          mat-stroked-button
          color="accent"
          class="mr-4 mb-1"
          (click)="rejectBusinessCaseCreation()"
        >
          Reject soft services
        </button>
        <button
          mat-flat-button
          color="accent"
          (click)="approveBusinessCaseCreation()"
        >
          Approve soft services
        </button>
      }
    </span>
  </bidvest-business-case-header>
  <div class="px-2" *ngIf="businessCaseId">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> Code </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>

      <ng-container matColumnDef="serviceName">
        <th mat-header-cell *matHeaderCellDef> Service name </th>
        <td mat-cell *matCellDef="let element"> {{ element.serviceName}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell *matCellDef="let element">
          <span class="px-2 py-1 rounded-md">{{element.category}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="subCategory">
        <th mat-header-cell *matHeaderCellDef> Sub Category </th>
        <td mat-cell *matCellDef="let element">
          {{ element.subCategory}}
        </td>
      </ng-container>

      <ng-container matColumnDef="unitCost">
        <th mat-header-cell *matHeaderCellDef>Unit cost </th>
        <td mat-cell *matCellDef="let element">
          <span class="px-2 py-1">R {{element.unitCost}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="uom">
        <th mat-header-cell *matHeaderCellDef> UOM </th>
        <td mat-cell *matCellDef="let element">
          <span class="px-2 py-1">{{element.unitOfMeasure}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef> Quantity </th>
        <td mat-cell *matCellDef="let element">
          <span class="px-2 py-1" [attr.data-id]="element.id"></span>
        </td>
      </ng-container>

      <tr mat-header-row class="table-headers" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
