import {
  Component,
  effect,
  inject,
  input,
  InputSignal,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { backOfficeRouteKeys, FileUploadComponent } from '@bidvest/shared';

import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { BusinessCaseService } from '../../services/business-case.service';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { IBusinessCaseAccount } from '../../interfaces/collaborator.interface';
import {
  Collaborator,
  UpdateBusinessCasePayload
} from '../../interfaces/update-business-case-payload.interface';
import { firstValueFrom, Subject } from 'rxjs';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { MatChipsModule } from '@angular/material/chips';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DocumentUpdateDialogComponent } from '../dialogs/document-update-dialog/document-update-dialog.component';
import { IStageCollaborationInput } from '../../interfaces/collaborator-input.interface';
import { CollaboratorsSelectorComponent } from '../collaborators-selector/collaborators-selector.component';
import { environment } from '@env/environment';
import { FileSaverService } from 'ngx-filesaver';
import { IBusinessCaseDocument } from '../../interfaces/business-case-document.interface';

@Component({
  selector: 'bidvest-business-case-details-form',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    FileUploadComponent,
    MatOptionModule,
    MatSelectModule,
    MatChipsModule,
    CollaboratorsSelectorComponent
  ],
  templateUrl: './business-case-details-form.component.html',
  styleUrl: './business-case-details-form.component.scss',
  providers: [provideMomentDateAdapter()]
})
export class BusinessCaseDetailsFormComponent implements OnInit, OnDestroy {
  public businessCaseDetails: InputSignal<IBusinessCaseDetails | undefined> =
    input();

  public stageConfigs: InputSignal<
    Array<IBusinessCaseStageConfig> | undefined
  > = input();

  public isReadOnly: InputSignal<boolean> = input(false);

  public businessCaseStages: Array<BusinessCaseStage> = [];
  public businessCaseAccounts: Array<IBusinessCaseAccount> = [];
  public bcReviewers: Record<string, Array<string>> = {};
  public businessCaseForm!: FormGroup;
  public selectedCollaborators: Array<IStageCollaborationInput> = [];
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public baseUrl: string = environment.apiUrl;
  public requiredDocs: {
    cdaLayout: boolean;
    iopSchedule: boolean;
    rr03PropertyList: boolean;
  } = {
    cdaLayout: false,
    iopSchedule: false,
    rr03PropertyList: false
  };
  public businessCaseActionTypes: Array<string> = [
    'TERMINATION',
    'DOWN_SELL',
    'UPSELL',
    'NEW_BUILDING'
  ];

  private readonly _businessCaseService: BusinessCaseService =
    inject(BusinessCaseService);
  private readonly _fb: FormBuilder = inject(FormBuilder);
  private readonly _toastr: ToastrService = inject(ToastrService);
  private readonly _loader: NgxUiLoaderService = inject(NgxUiLoaderService);
  private readonly _dialog: MatDialog = inject(MatDialog);
  private readonly _destroy$: Subject<void> = new Subject<void>();
  private readonly _router: Router = inject(Router);
  private readonly _fileSaverService: FileSaverService =
    inject(FileSaverService);

  constructor() {
    effect(() => {
      const businessCaseDetails =
        this.businessCaseDetails() as IBusinessCaseDetails;

      if (businessCaseDetails) {
        this.businessCaseStages = businessCaseDetails.collaborators.map(
          (stage) => ({
            id: stage.id,
            stageName: stage.businessCaseStageName
          })
        );

        this.checkRequiredDocs(businessCaseDetails);
        this.prepopulateForm(businessCaseDetails);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.businessCaseForm = this._fb.group({
      businessCaseActionType: ['', Validators.required],
      name: ['', Validators.required],
      implementationDate: [new Date(), Validators.required],
      description: ['', Validators.required],
      town: ['', Validators.required],
      province: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      outletType: ['', Validators.required],
      outlet: ['', Validators.required]
    });

    await this.getBusinessCaseAccounts();

    const bcCollaborators = this.businessCaseDetails()
      ?.collaborators as Array<Collaborator>;

    const bcStageNames = bcCollaborators.map((c) => c.businessCaseStageName);

    for (let name of bcStageNames) {
      this.bcReviewers[name] = [];
    }

    for (let name of bcStageNames) {
      const stageReviewerIds: Array<number> = bcCollaborators.find(
        (c) => c.businessCaseStageName === name
      )?.reviewerIds as Array<number>;

      for (let id of stageReviewerIds) {
        const account = this.businessCaseAccounts.find(
          (account) => account.id === id
        ) as IBusinessCaseAccount;

        this.bcReviewers[name].push(account.fullName);
      }
    }

    if (this.isReadOnly()) {
      this.businessCaseForm.disable();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async getBusinessCaseAccounts(): Promise<void> {
    try {
      const data = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseAccounts()
      );
      this.businessCaseAccounts = [...data];
    } catch (error) {}
  }

  public prepopulateForm(data: IBusinessCaseDetails): void {
    this.businessCaseForm.patchValue({
      businessCaseActionType: data.businessCaseActionType,
      name: data.businessCaseName,
      implementationDate: new Date(data.implementationDate),
      description: data.businessCaseDescription,
      town: data.town,
      province: data.province,
      buildingNumber: data.buildingNumber,
      outletType: data.outletType,
      outlet: data.outlet
    });
  }

  public showDocumentUpdateDialog(): void {
    this._dialog.open(DocumentUpdateDialogComponent, {
      width: '450px',
      height: '500px',
      data: {
        businessCaseId: this.businessCaseDetails()?.id as number
      }
    });
  }

  public async updateBusinessCase(): Promise<void> {
    if (
      this.businessCaseForm.valid &&
      this.areCollaboratorsValid() &&
      this.requiredDocs.cdaLayout === true &&
      this.requiredDocs.iopSchedule === true &&
      this.requiredDocs.rr03PropertyList === true
    ) {
      const {
        businessCaseActionType,
        name,
        implementationDate,
        description,
        town,
        province,
        buildingNumber,
        outletType,
        outlet
      } = this.businessCaseForm.getRawValue();

      const payload: UpdateBusinessCasePayload = {
        id: this.businessCaseDetails()?.id as number,
        referenceNumber: this.businessCaseDetails()?.referenceNumber as string,
        businessCaseName: name,
        buildingNumber,
        implementationDate,
        businessCaseActionType,
        writeUp: '',
        town,
        province,
        outlet,
        outletType,
        collaborators: [...this.selectedCollaborators],
        businessCaseDescription: description
      };

      try {
        await firstValueFrom(
          this._businessCaseService.updateBusinessCase(payload)
        );

        this._toastr.success(
          `Business Case ${
            this.businessCaseDetails()?.referenceNumber
          } has been updated successfully`
        );
        this._businessCaseService.businessCaseTableFilter.next(
          this._businessCaseService.defaultFilter
        );

        this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
      } catch (error) {
        console.log(error);
      }
    }
  }

  async submitBusinessCaseForReview(): Promise<void> {
    if (
      this.businessCaseForm.valid &&
      this.areCollaboratorsValid() &&
      this.requiredDocs.cdaLayout === true &&
      this.requiredDocs.iopSchedule === true &&
      this.requiredDocs.rr03PropertyList === true
    ) {
      const {
        businessCaseActionType,
        name,
        implementationDate,
        description,
        town,
        province,
        buildingNumber,
        outletType,
        outlet
      } = this.businessCaseForm.getRawValue();

      this._loader.start();

      const payload: UpdateBusinessCasePayload = {
        id: this.businessCaseDetails()?.id as number,
        referenceNumber: this.businessCaseDetails()?.referenceNumber as string,
        businessCaseName: name,
        buildingNumber,
        implementationDate,
        businessCaseActionType,
        writeUp: '',
        town,
        province,
        outlet,
        outletType,
        collaborators: [...this.selectedCollaborators],
        businessCaseDescription: description
      };

      try {
        await firstValueFrom(
          this._businessCaseService.updateBusinessCase(payload)
        );

        await firstValueFrom(
          this._businessCaseService.submitBusinessCaseForReview({
            businessCaseId: this.businessCaseDetails()?.id as number,
            businessCaseStageId: this.businessCaseDetails()
              ?.activeStageId as number
          })
        );

        this._loader.stop();

        this._toastr.success(
          `Business Case ${
            this.businessCaseDetails()?.referenceNumber
          } has been submitted for review`
        );
        this._businessCaseService.businessCaseTableFilter.next(
          this._businessCaseService.defaultFilter
        );
        this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
      } catch (error) {
        console.log(error);
      }
    }
  }

  public getCollaboratorSelection(val: IStageCollaborationInput) {
    const stageSelections = this.selectedCollaborators.find(
      (data) => data.businessCaseStageName === val.businessCaseStageName
    );
    if (stageSelections) {
      stageSelections.ownerId = val.ownerId;
      stageSelections.reviewerIds = [...val.reviewerIds];
      stageSelections.id = val.id;
    } else {
      this.selectedCollaborators.push(val);
    }
  }

  public areCollaboratorsValid(): boolean {
    return this.selectedCollaborators.every(
      (val) =>
        val.ownerId !== null &&
        val.businessCaseStageName !== null &&
        val.reviewerIds.length > 0 &&
        val.id !== null
    );
  }

  public checkRequiredDocs(data: IBusinessCaseDetails): void {
    data.documents.forEach((doc) => {
      if (doc.documentType === 'CONSTRUCTION_DRAWING') {
        this.requiredDocs.cdaLayout = true;
      } else if (doc.documentType === 'IOP_SCHEDULE') {
        this.requiredDocs.iopSchedule = true;
      } else if (doc.documentType === 'REASON_FOR_DELAY') {
        this.requiredDocs.rr03PropertyList = true;
      }
    });
  }

  public getStageOwner(stage: BusinessCaseStage): string {
    const stageOwnerName = this.stageConfigs()?.find(
      (config) => config.stageId === stage.id
    )?.stageOwner;

    return stageOwnerName as string;
  }

  public downloadDocument(doc: IBusinessCaseDocument): void {
    const url = `business-cases/${this.businessCaseDetails()?.id}/documents/${
      doc.id
    }/${this.businessCaseDetails()?.referenceNumber}/${doc.documentType}/${
      doc.guid
    }/${doc.documentSystemName}`;
    this._businessCaseService.downloadBCDocument(url).subscribe({
      next: (val) => {
        this._fileSaverService.save(val.body, `${doc.documentName}`);
      }
    });
  }
}
