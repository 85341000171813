<bidvest-business-case-header *ngIf="businessCaseDetails">
    <span
      ngProjectAs="business-case-header-back-btn"
      [routerLink]="routes['BUSINESS_CASE'] + '/' + businessCaseId"
    >
      <button mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
  <span
    ngProjectAs="business-case-header-title"
    [matTooltip]="businessCaseDetails?.businessCaseName!"
  >
      {{ businessCaseDetails?.businessCaseName | titlecase }}
    </span>
  <span ngProjectAs="business-case-header-number">
      {{ businessCaseDetails?.referenceNumber }}
    </span>
  <span ngProjectAs="business-case-header-actions">
    @if(businessCaseDetails?.activeStageName === 'COST_SOFT_SERVICES' && (businessCaseDetails?.activeStageStatus === 'DRAFT' || businessCaseDetails?.activeStageStatus === 'REJECTED') && isEditor()) {
      <button
        mat-stroked-button
        color="accent"
        (click)="review()"
      >
        Submit for review
      </button>

      <button type="button"
        mat-flat-button color="accent"
        (click)="submit()">
        Add soft service
      </button>
    }
    </span>
</bidvest-business-case-header>
<div>
  <div>
    <div class="heading-container">
      <p class="heading-text">Add Soft Service</p>
      <p class="sub-heading-text">Select soft services you want to added to this business case </p>
    </div>
  </div>
  <div class="mt-4">
    <div>
      <div class="flex justify-between gap-4  items-center bg-white px-2 py-4">
        <div class="flex gap-4 justify-center">
          <form class="max-w-sm">
            <mat-form-field class="search-bar" appearance="outline">
              <mat-label class="search-bar-label">Search</mat-label>
              <input style="font-size: 12px" type="text" matInput placeholder="Search" [(ngModel)]="searchValue" name="searchTerm" />
              <mat-icon class="white-icon" (click)="triggerChange()" matSuffix>search</mat-icon>
            </mat-form-field>
          </form>

          <div class="flex items-center gap-4">
            <mat-form-field appearance="outline">
              <mat-label>Filter</mat-label>
              <mat-select [(ngModel)]="categoryValue">
                @for (categories of filterCategoriesKeys; track categories) {
                <mat-option [value]="categories" (click)="applyFilter(categories)">{{categories}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="px-2">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> Code </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>

      <ng-container matColumnDef="serviceName">
        <th mat-header-cell *matHeaderCellDef> Service name </th>
        <td mat-cell *matCellDef="let element"> {{ element.serviceName}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell *matCellDef="let element">
          <span class="px-2 py-1 rounded-md">{{element.category}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="subCategory">
        <th mat-header-cell *matHeaderCellDef> Sub Category </th>
        <td mat-cell *matCellDef="let element">
          {{ element.subCategory}}
        </td>
      </ng-container>

      <ng-container matColumnDef="unitCost">
        <th mat-header-cell *matHeaderCellDef>Unit cost </th>
        <td mat-cell *matCellDef="let element">
          <span class="px-2 py-1">{{element.unitCost}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="uom">
        <th mat-header-cell *matHeaderCellDef> UOM </th>
        <td mat-cell *matCellDef="let element">
          <span class="px-2 py-1">{{element.unitOfMeasure}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef> Quantity </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="px-2 py-4"
          >
            <mat-label
              matTooltip="Add quantity"
              class="flex flex-row items-center"
            >Quantity</mat-label>
            <input type="number" min="0" matInput [attr.data-id]="element.id" name="quantity"/>
          </mat-form-field>
        </td>
      </ng-container>

      <tr mat-header-row class="table-headers" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="resultsLength" [pageSize]="size" aria-label="Select page">
    </mat-paginator>

  </div>

</div>
