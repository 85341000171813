<div class="p-4">
  <h4 class="font-semibold">Update business case document</h4>
</div>
<div matDialogContent>
  <div class="flex flex-col justify-start">
    <label id="example-radio-group-label"
      >Please select a document type to update</label
    >
    <mat-radio-group
      class="example-radio-group"
      [(ngModel)]="selectedDocumentType"
    >
      @for (documentType of documentTypes; track documentType) {
      <mat-radio-button class="example-radio-button" [value]="documentType">{{
        documentType
      }}</mat-radio-button>
      }
    </mat-radio-group>
  </div>

  <div class="flex flex-col justify-center items-center mt-4">
    <ng-container *ngIf="selectedDocumentType === 'CONSTRUCTION DRAWING'">
      <bidvest-file-upload
        (uploadSuccess)="requiredDocs.cdaLayout = true"
        [allowedMimeTypes]="['.pdf', '.png', '.docx', '.jpg', '.jpeg']"
        [businessCaseId]="data.businessCaseId"
        [url]="'documents/CONSTRUCTION_DRAWING'"
        [required]="true"
      ></bidvest-file-upload>
      <small class="font-semibold">CDA Layout</small>
    </ng-container>
    <ng-container *ngIf="selectedDocumentType === 'IOP SCHEDULE'">
      <bidvest-file-upload
        (uploadSuccess)="requiredDocs.iopSchedule = true"
        [allowedMimeTypes]="['.pdf', '.png', '.docx', '.jpg', '.jpeg']"
        [businessCaseId]="data.businessCaseId"
        [url]="'documents/IOP_SCHEDULE'"
        [required]="true"
      ></bidvest-file-upload>
      <small class="font-semibold">IOP Schedule</small>
    </ng-container>
    <ng-container *ngIf="selectedDocumentType === 'REASON FOR DELAY'">
      <bidvest-file-upload
        (uploadSuccess)="requiredDocs.rr03PropertyList = true"
        [allowedMimeTypes]="['.pdf', '.png', '.docx', '.jpg', '.jpeg']"
        [businessCaseId]="data.businessCaseId"
        [url]="'documents/REASON_FOR_DELAY'"
        [required]="true"
      ></bidvest-file-upload>
      <small class="font-semibold">RR03 Peoperty list</small>
    </ng-container>
  </div>
</div>
