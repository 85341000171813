import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { RateCardsService } from '../../../services/rate-cards.service';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { UnitsOfMeasurement } from '../../../enums/units-of-measurement.enum';
import { Category } from '../../../enums/category.enum';
import { SubCategory } from '../../../enums/sub-category.enum';
import { RateCardGroup } from '../../../enums/rate-card-group.enum';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CustomToastrService } from '../../../services/custom-toastr.service';
interface ICategories {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'bidvest-create-rate-card-overlay',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDividerModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions
  ],
  templateUrl: './create-rate-card-overlay.component.html',
  providers: [
    provideNativeDateAdapter(),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
        appearance: 'outline'
      }
    }
  ],
  styleUrl: './create-rate-card-overlay.component.scss'
})
export class CreateRateCardOverlayComponent implements OnInit {
  public form!: FormGroup;
  public showPassword: boolean = false;
  public disableButtonValue: boolean = true;


  public unitsOfMeaurement: ICategories[] = [
    { value: 'Each', viewValue: UnitsOfMeasurement.EACH },
    { value: 'Bale', viewValue: UnitsOfMeasurement.BALE },
    { value: 'Hour', viewValue: UnitsOfMeasurement.HOUR },
    { value: 'Km', viewValue: UnitsOfMeasurement.KM },
    { value: 'Month', viewValue: UnitsOfMeasurement.MONTH },
    { value: 'Sqm', viewValue: UnitsOfMeasurement.SQM },
    { value: 'Week', viewValue: UnitsOfMeasurement.WEEK },
  ];

  public categories: ICategories[] = [
    { value: 'Cleaning', viewValue: Category.CLEANING },
    { value: 'Hygiene', viewValue: Category.HYGIENE },
    { value: 'Shredding', viewValue: Category.SHREDDING },
    { value: 'Staff', viewValue: Category.STAFF },
  ]

  public subCategories: ICategories[] = [
    { value: 'Cleaner', viewValue: SubCategory.CLEANER },
    { value: 'Supervisor', viewValue: SubCategory.SUPERVISOR },
    { value: 'Supply Deliver', viewValue: SubCategory.SUPPLY_DELIVER },

  ]

  public rateCardGroup: ICategories[] = [
    { value: 'Engineering services', viewValue: RateCardGroup.ENGINEERING_SERVICES },
    { value: 'Soft services', viewValue: RateCardGroup.SOFT_SERVICES },
    { value: 'Other', viewValue: RateCardGroup.OTHER }
  ]

  public constructor(
    private readonly _fb: FormBuilder,
    private readonly _rateCardService: RateCardsService,
    private readonly _loader: NgxUiLoaderService,
    private _dialogRef: MatDialogRef<CreateRateCardOverlayComponent>,
    private readonly _toast: ToastrService,
    private _customToastr: CustomToastrService
  ) { }

  public ngOnInit(): void {
    this.initializeForm();
  }
  public addNewRateCard() {

    this._loader.start();
    if (this.form['status'] === "VALID") {
      this._rateCardService.createRateCard(this.form.value).subscribe(
        () => {
          this._customToastr.openSuccessToast(
            'Rate card has been created successfully.',
            'success-toast',
            'right',
            'top'
          );
        }
      );
      this._loader.stop();
      this._dialogRef.close();
    } else {
      this._customToastr.openErrorToast(
        'Error',
        'An error has occurred while trying to update the rate card.',
        'warning',
        'error-toast',
        'right',
        'top'
      );
      this._loader.stop();
      this._dialogRef.close();
    }
  }

  private initializeForm(): void {
    this.form = this._fb.group({
      serviceName: ['', [Validators.required]],
      serviceDescription: ['', [Validators.required]],
      unitOfMeasure: ['', [Validators.required]],
      category: ['', [Validators.required]],
      subCategory: ['', [Validators.required]],
      rateCardGroup: ['', [Validators.required]],
      unitCost: [0, [Validators.required]],
      code: ['16474', [Validators.required]],
      notes: ['', [Validators.required]],
      validTillDate: ['', [Validators.required]],
      vendorName: ['', [Validators.required]]
    });
  }

  public closeRateCardOverlay() {
    this._dialogRef.close();
  }
}
