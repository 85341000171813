import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Injectable,
  inject,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { TokenService } from '@bidvest/shared';
import { AuthenticationService } from 'libs/bidvest-ui/auth/src/lib/auth/services';
import { IRateCardDataType } from '../../../models/rate-card-data';

@Component({
  selector: 'bidvest-soft-delete-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDividerModule
  ],
  templateUrl: './staff-soft-delete-overlay.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './staff-soft-delete-overlay.component.scss'
})
export class StaffSoftDeleteOverlayComponent implements OnInit {
  public form!: FormGroup;
  // public routes: { [key: string]: string } = backOfficeRouteKeys;
  public showPassword: boolean = false;

  types: any[] = [
    { value: '0', viewValue: 'One' },
    { value: '1', viewValue: 'Two' },
    { value: '2', viewValue: 'Three' }
  ];

  public constructor(
    private readonly _fb: FormBuilder,
  ) {}

  public ngOnInit(): void {
    this.initializeForm();
  }

  

  private initializeForm(): void {
    this.form = this._fb.group({
      stageOwner: ['', [Validators.required]],
      reviewer: ['', [Validators.required]],
    });
  }
}
