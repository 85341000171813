import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { firstValueFrom } from 'rxjs';
import { IViewStaffData } from '../models/view-staff-data';
import { IUpdateStaffData } from '../models/update-staff-data';

@Injectable({
  providedIn: 'root'
})
export class StaffTableService {
  private baseUrl: string = environment.apiUrl;
  private httpClient: HttpClient = inject(HttpClient);

  public async viewSelectedStaff(id: number): Promise<IViewStaffData> {
    return  firstValueFrom(this.httpClient.get<IViewStaffData>(`${this.baseUrl}/accounts/${id}`))
  }

  public async updateSelectedStaff(data: IUpdateStaffData) {
    return firstValueFrom(this.httpClient.put<null>(`${this.baseUrl}/accounts`, data))
  }

}
