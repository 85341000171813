import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { VerifyAssetTableComponent } from '../../components/tables/verify-asset-table/verify-asset-table.component';
import { ActivatedRoute, ParamMap, RouterLink } from '@angular/router';
import { backOfficeRouteKeys, BusinessCaseHeaderComponent } from '@bidvest/shared';
import { BusinessCaseActivityLogComponent } from '../../components/business-case-activity-log/business-case-activity-log.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { AddToAssetListOverlayComponent } from '../../components/overlays/add-to-asset-list/add-to-asset-list-overlay.component';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { BusinessCaseService } from '../../services/business-case.service';

@Component({
  selector: 'bidvest-staff-table-layout',
  standalone: true,
  imports: [
    VerifyAssetTableComponent,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    RouterLink,
    BusinessCaseHeaderComponent,
    FormsModule
  ],
  templateUrl: './verify-asset-list.component.html',
  styleUrl: './verify-asset-list.component.scss'
})
export class VerifyAssetListComponent implements OnInit {

  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public businessCase: IBusinessCaseDetails | null = null;
  public businessCaseId!: number;


  constructor(
    private readonly _overlay: Overlay,
    private readonly _dialog: MatDialog,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _route: ActivatedRoute
  ) { }
  async ngOnInit(): Promise<void> {
    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    this.businessCaseId = routeParams.get('id') as unknown as number;
    this.businessCase = await firstValueFrom(
      this._businessCaseService.fetchBusinessCaseById(this.businessCaseId))
  }

  public openAddAssetOverlay() {
    this._dialog.open(AddToAssetListOverlayComponent, {
      width: '570px',
      height: '570px'
    });
  }

  public showActivityLogDialog(): void {
    const overlayRef = this._overlay.create({
      width: '450px',
      height: '600px',
      backdropClass: 'bg-slate-400/50',
      panelClass: ['overflow-y-auto', 'rounded-lg'],
      hasBackdrop: true,
      positionStrategy: new GlobalPositionStrategy()
        .bottom('20px')
        .right('15px'),
      scrollStrategy: this._overlay.scrollStrategies.block()
    });
    const activityLogPortal = new ComponentPortal(
      BusinessCaseActivityLogComponent
    );
    const componentRef = overlayRef.attach(activityLogPortal);

    componentRef.setInput('businessCaseId', this.businessCase?.id as number);
    componentRef.instance.overlayRef = overlayRef;
  }

}
