import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Injectable, inject, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetListActionStatusEnum } from '../../../enums/asset-list-actions.enum';




@Component({
  selector: 'bidvest-asset-list-seen-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule
  ],
  templateUrl: './asset-list-seen-overlay.component.html',
  providers: [],
  styleUrl: './asset-list-seen-overlay.component.scss'
})
export class AssetListSeenOverlayComponent implements OnInit {
  public actionStatusOptions = Object.values(AssetListActionStatusEnum);
  public form!: FormGroup;

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly _data: {},  
    private readonly _fb: FormBuilder,
    private readonly _toast: ToastrService,
    private readonly _dialogRef: MatDialogRef<AssetListSeenOverlayComponent>,
  ) { }

  public ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.form = this._fb.group({
      sapId: ['', [Validators.required]],
      sapName: ['', [Validators.required]],
      description: ['', [Validators.required]],
      actionStatus: ['', [Validators.required]],
    });
  }

  public closeAssetList() {
    this._dialogRef.close();
  }
}
