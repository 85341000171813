<div class="w-full h-full flex flex-col justify-start p-6">
    <div class="flex max-w-lg mx-auto items-center">
        <div>
            <div class="flex flex-row justify-between">
                <p class="font-normal text-blue-800 text-2xl mb-8">
                    Add Asset
                </p>
                <mat-icon class="text-gray-400 cursor-pointer" (click)="closeAssetList()">close</mat-icon>
            </div>
            <div class="pt-2 pb-4">
                <mat-divider></mat-divider>
            </div>
            <div class="py-2"></div>
            <form [formGroup]="form" class="px-4">
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>SAP ID</mat-label>
                    <mat-select type="text" matInput formControlName="sapId">
                        @for (sapIdData of sapIdData; track sapIdData) {
                        <mat-option [value]="sapIdData.value">
                            {{sapIdData.sapId}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>SAP name</mat-label>
                    <input type="text" matInput formControlName="sapName" />
                </mat-form-field>
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Description</mat-label>
                    <input type="text" matInput formControlName="description" />
                </mat-form-field>
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>High Level Description</mat-label>
                    <textarea type="text" matInput formControlName="highLevelDescription"></textarea>
                </mat-form-field>
                <div class="pb-2 pt-6">
                    <mat-divider></mat-divider>
                </div>
                <div class="flex flex-row justify-between items-center grid grid-cols-2 gap-2">
                    <button mat-stroked-button color="accent" [disabled]="!form.valid">
                        <mat-icon>add</mat-icon>
                        Add another asset
                    </button>
                    <button (click)="closeAssetList()" mat-flat-button color="accent" [disabled]="!form.valid">
                        Done
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>