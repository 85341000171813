<div class="w-full h-full flex flex-col justify-start p-6">
    <div class="flex max-w-lg mx-auto items-center">
        <div class="overlay-container ">
          <div class="flex flex-row justify-between">
            <h2 class="font-semibold text-blue-800 text-2xl p-4">
                Update Rate Card
            </h2>
            <mat-icon (click)="closeOverlay()" class="text-gray-400 cursor-pointer">close</mat-icon>
          </div>
            <form [formGroup]="form" (ngSubmit)="updateRateCard()">
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Service name</mat-label>
                    <input type="text" matInput formControlName="serviceName"/>
                </mat-form-field>
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Service description</mat-label>
                    <input type="text" matInput placeholder="Category" formControlName="serviceDescription"/>
                </mat-form-field>
                <mat-form-field class="w-full mb-4 col-span-6">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category">
                      @for (category of categories; track category) {
                      <mat-option [value]="category.viewValue">{{ category.value }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
            
                  <mat-form-field class="w-full mb-4 col-span-6">
                    <mat-label>Sub-category</mat-label>
                    <mat-select formControlName="subCategory">
                      @for (subCategory of subCategories; track subCategory) {
                      <mat-option [value]="subCategory.viewValue">{{ subCategory.value }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="w-full mb-4 col-span-6">
                    <mat-label>Rate card group</mat-label>
                    <mat-select formControlName="rateCardGroup">
                      @for (rateCardGroup of rateCardGroup; track rateCardGroup) {
                      <mat-option [value]="rateCardGroup.viewValue">{{ rateCardGroup.value }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Unit cost</mat-label>
                    <input type="text" matInput placeholder="R" defaultValue="R" formControlName="unitCost"/>
                </mat-form-field>
                <mat-form-field class="w-full mb-4 col-span-6">
                    <mat-label>UOM</mat-label>
                    <mat-select formControlName="unitOfMeasure">
                      @for (unitsOfMeaurements of unitsOfMeaurement; track unitsOfMeaurements) {
                      <mat-option [value]="unitsOfMeaurements.viewValue">{{ unitsOfMeaurements.value }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Notes</mat-label>
                    <textarea type="text" matInput placeholder="Notes" formControlName="notes"></textarea>
                </mat-form-field>
                <br>
                <mat-divider></mat-divider>
                <br>
                <mat-form-field class="w-full flex" appearance="outline">
                    <mat-label>Vendor name</mat-label>
                    <input type="text" matInput placeholder="Vendor name" formControlName="vendorName"/>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="w-full mb-4">
                  <mat-label matTooltip="Select the date until valid" class="flex flex-row items-center">Valid unitl
                    <mat-icon class="ml-2">info</mat-icon>
                  </mat-label>
          
                  <input matInput [matDatepicker]="picker" formControlName="validTillDate" />
          
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <div class="w-full flex flex-row">
                    <button class="w-full" (click)="updateRateCard()" mat-flat-button color="accent" [disabled]="!form.valid"
                        >
                        Update rate card
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>