<div
  class="w-full h-screen overflow-y-auto bg-slate-200 p-4 flex flex-col justify-start"
>
  <bidvest-business-case-header>
    <span ngProjectAs="business-case-header-back-btn">
      <button mat-icon-button [routerLink]="routes['BUSINESS_CASES']">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span ngProjectAs="business-case-header-title">Test</span>
    <span ngProjectAs="business-case-header-number">1234</span>
    <span ngProjectAs="business-case-header-actions">
      <button mat-stroked-button color="accent">Submit for review</button>
    </span>
  </bidvest-business-case-header>

  <div class="mt-4 mb-1 bg-white p-6 rounded-lg flex flex-col">
    <h4 class="text-primary-blue text-2xl font-bold">Soft services</h4>
    <p class="font-bold text-sm text-gray-400">
      Select soft services you want to add to this business case
    </p>
  </div>

  <div class="bg-white rounded-lg p-6">
    <div class="flex justify-between items-center py-6">
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input type="text" matInput placeholder="Search" name="searchTerm" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <span class="flex-row flex items-center justify-center">
        <button mat-button color="accent" class="mr-2">
          <mat-icon>add</mat-icon>
          Add comment
        </button>
        <button mat-stroked-button color="accent">
          <mat-icon>add</mat-icon>
          Add soft service
        </button>
      </span>
    </div>
    <table mat-table [dataSource]="dataSource" appearance="outline">
      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox> </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td mat-cell *matCellDef="let element">{{ element.code }}</td>
      </ng-container>

      <ng-container matColumnDef="serviceName">
        <th mat-header-cell *matHeaderCellDef>Service name</th>
        <td mat-cell *matCellDef="let element">{{ element.serviceName }}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let element">{{ element.category }}</td>
      </ng-container>

      <ng-container matColumnDef="subCategory">
        <th mat-header-cell *matHeaderCellDef>Sub category</th>
        <td mat-cell *matCellDef="let element">{{ element.subCategory }}</td>
      </ng-container>

      <ng-container matColumnDef="unitCost">
        <th mat-header-cell *matHeaderCellDef>Unit cost</th>
        <td mat-cell *matCellDef="let element">R {{ element.unitCost }}</td>
      </ng-container>

      <ng-container matColumnDef="uom">
        <th mat-header-cell *matHeaderCellDef>UOM</th>
        <td mat-cell *matCellDef="let element">{{ element.uom }}</td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
