export enum BusinessCaseStagesEnum {
  SETUP = 'business case setup',
  COST_SOFT_SERVICES = 'Soft services',
  COST_ENGINEERING_SERVICES = 'Engineering services',
  VERIFY_ASSET_LIST = 'Verify asset list',
  GATHER_ASSET_PRICE = 'Gather asset price',
  PROCUREMENT_TO_PRICE = 'Procurement',
  PREPARE_ENGINEERING_QUOTE = 'Prepare engineering quote',
  ENGINEERING_REVIEW = 'Engineering review',
  GOVERNANCE_REVIEW = 'Governance review',
  LEGAL_REVIEW = 'Legal review',
  CUSTOMER_FINANCE_REVIEW = 'Customer finance review',
  CUSTOMER_ENGINEERING_REVIEW = 'Customer engineering review',
  CUSTOMER_FINAL_REVIEW = 'Customer final review',
  GENERATE_CHANGE_NOTE = 'Generate change note'
}
