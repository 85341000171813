import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatFormFieldModule
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'bidvest-asset-replacing-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDividerModule,
    MatDialogTitle,
    MatDividerModule,
    MatDialogContent,
    MatDialogActions
  ],
  templateUrl: './asset-replacing-overlay.component.html',
  providers: [],
  styleUrl: './asset-replacing-overlay.component.scss'
})
export class AssetReplaceOverlayComponent implements OnInit {
  public form!: FormGroup;

  public constructor(
    private readonly _fb: FormBuilder,
    private readonly _loader: NgxUiLoaderService,
    private readonly _toast: ToastrService,
  ) { }

  public ngOnInit(): void {
    this.initializeForm();
  }

  public addNewRateCard() {
    this._loader.start();
    if (this.form['status'] === "VALID") {
      this._toast.success('New rate card has been added successfully'); 
      this._loader.stop();
    } else {
      this._toast.error('An error has occurred while trying to update the rate card.');
      this._loader.stop();
    }
  }

  private initializeForm(): void {
    this.form = this._fb.group({
      category: ['', [Validators.required]],
      subCategory: ['', [Validators.required]],
      sapId: ['', [Validators.required]],
      sapName: ['', [Validators.required]],
      highLevelDescription: ['', [Validators.required]]
    });
  }
}
