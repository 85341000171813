import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { IBusinessCaseTableItem } from '../../models/business-case-table-item';
import { BusinessCaseTableAction } from '../../models/business-case-table-action';
import { IBusinessCaseGeneration } from '../../models/business-case-generation';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BusinessCaseAction } from '../../enums/business-case-actions.enum';
import { compareDesc } from 'date-fns';

@Component({
  selector: 'bidvest-business-cases-table',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    DatePipe
  ],
  templateUrl: './business-cases-table.component.html',
  styleUrl: './business-cases-table.component.scss'
})
export class BusinessCasesTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  @Input()
  public set data(val: Array<IBusinessCaseTableItem>) {
    if (val) {
      this._data = [...val];
      this.dataSource.data = this._data.sort((a, b) =>
        compareDesc(a.initiationDate, b.initiationDate)
      );
    }
  }

  public get data(): Array<IBusinessCaseTableItem> {
    return this._data;
  }

  @Output()
  public actions: EventEmitter<BusinessCaseTableAction> = new EventEmitter();

  public businessCaseData!: IBusinessCaseGeneration;

  public displayedColumns: Array<string> = [
    'caseNumber',
    'businessCaseName',
    'implementationDate',
    'initiationDate',
    'buildingNumber',
    'handler',
    'stage',
    'status',
    'actions'
  ];

  public dataSource = new MatTableDataSource<IBusinessCaseTableItem>([]);

  public selection = new SelectionModel<IBusinessCaseTableItem>(true, []);

  private _data: Array<IBusinessCaseTableItem> = [];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  isAllSelected(): boolean {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  viewBusinessCase(id: number, status: string): void {
    this.actions.emit({
      actionType: BusinessCaseAction.VIEW,
      id,
      data: { status }
    });
  }

  deleteBusinessCase(id: number, refNo: string): void {
    this.actions.emit({
      actionType: BusinessCaseAction.DELETE,
      id,
      data: { refNo }
    });
  }
}
