import { CommonModule } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { CustomToastrService } from "../../../services/custom-toastr.service";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: 'bidvest-alert-toast-component',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './alert-toastr.component.html',
  providers: [],
  styleUrl: './alert-toastr.component.scss'
})
export class AlertToastComponent {
  public constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data:{
      title: string,
      description: string,
      type: 'warning' |'info',
      button: boolean,
      buttonText: string,
      buttonAction: () => void
    } ,
    private toastrService: CustomToastrService
  ) {}

public closeOnClick() {
  this.toastrService.closeToastr();
}

}
