<div class="mt-2 rounded-md">
  <div>
    <div class="flex justify-between gap-4 items-center bg-white px-8 py-4">
      <div class="flex gap-4 justify-center">
        <form class="max-w-sm">
          <mat-form-field class="search-bar" appearance="outline">
            <mat-label class="search-bar-label">Search</mat-label>
            <input style="font-size: 12px" type="text" [(ngModel)]="searchTerm" matInput placeholder="Search" name="searchTerm" />
            <mat-icon class="white-icon" (click)="searchOnClick()" matSuffix>search</mat-icon>
          </mat-form-field>
        </form>

        <div class="flex items-center gap-4">
          <mat-form-field appearance="outline">
            <mat-label>Filter</mat-label>
            <mat-select>
              <mat-option [value]="" (click)="applyFilter('')">ALL</mat-option>
              @for (disciplines of filterOptions; track disciplines) {
                <mat-option [value]="disciplines" (click)="applyFilter(disciplines)">{{disciplines}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <a
        class="text-sm items-center justify-center font-semibold font-montserrat ms-center cursor-pointer font-bold text-primary-blue hover:text-primary-orange">
        <div class="flex-row flex items-center justify-center">
          <button (click)="showActivityLogDialog()" class="underlined-button">
            <mat-icon>add </mat-icon>
            Add comment
          </button>
        </div>
      </a>
    </div>
    <div class="grid grid-cols-3">
      <div class="col-span-1">
        <bidvest-drawing-view></bidvest-drawing-view>
      </div>
      <div class="col-span-2">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" appearance="outline">

            <ng-container matColumnDef="mandate">
              <th mat-header-cell *matHeaderCellDef> Mandate </th>
              <td mat-cell *matCellDef="let element"> {{element.mandate}} </td>
            </ng-container>

            <ng-container matColumnDef="siteId">
              <th mat-header-cell *matHeaderCellDef> Site ID </th>
              <td mat-cell *matCellDef="let element"> {{element.siteId}} </td>
            </ng-container>

            <ng-container matColumnDef="equipmentId">
              <th mat-header-cell *matHeaderCellDef> Equipment ID </th>
              <td mat-cell *matCellDef="let element"> {{element.equipmentId}} </td>
            </ng-container>

            <ng-container matColumnDef="strategy">
              <th mat-header-cell *matHeaderCellDef> Strategy </th>
              <td mat-cell *matCellDef="let element"> {{element.strategy}} </td>
            </ng-container>

            <ng-container matColumnDef="implementationDate">
              <th mat-header-cell *matHeaderCellDef> Implementation Date </th>
              <td mat-cell *matCellDef="let element"> {{element.implementationDate}} </td>
            </ng-container>

            <ng-container matColumnDef="refreshDate">
              <th mat-header-cell *matHeaderCellDef> Refresh Date </th>
              <td mat-cell *matCellDef="let element"> {{element.refreshDate}} </td>
            </ng-container>

            <ng-container matColumnDef="templateNumber">
              <th mat-header-cell *matHeaderCellDef> Template number </th>
              <td mat-cell *matCellDef="let element"> {{element.templateNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="discipline">
              <th mat-header-cell *matHeaderCellDef> Discipline </th>
              <td mat-cell *matCellDef="let element"> {{element.discipline}} </td>
            </ng-container>

            <ng-container matColumnDef="equipmentText">
              <th mat-header-cell *matHeaderCellDef> Equipment text </th>
              <td mat-cell *matCellDef="let element"> {{element.equipmentText}} </td>
            </ng-container>


            <ng-container matColumnDef="reject">
              <th mat-header-cell *matHeaderCellDef="reject"></th>
              <td mat-cell *matCellDef="let element">
                <button (click)="showUnseenAsset()" mat-icon-button>
                  <mat-icon>clear</mat-icon>
                </button>
                <button (click)="showSeenAsset()" mat-icon-button>
                  <mat-icon>done</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <div class="paginator-container">
          <mat-paginator [length]="pageLength" [pageSizeOptions]="[10, 15, 20, 25]" showFirstLastButtons aria-label="Select page of users">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>