<div class="py-4 border-b-[1px] flex flex-col justify-start w-full">
  <div class="flex flex-row justify-start mb-4">
    <div class="avatar"></div>
    <div class="flex flex-col w-full">
      <p class="font-semibold text-sm">{{ data.actionTitle }}</p>
      <p class="text-sm">{{ data.action }}</p>
    </div>
  </div>

  <div class="w-full flex flex-row justify-end divide-x-[1px]">
    <span class="px-2 text-xs">{{
      data.createdDateTime | date: 'shortDate'
    }}</span>
    <span class="px-2 text-xs">{{
      data.createdDateTime | date: 'shortTime'
    }}</span>
  </div>
</div>
