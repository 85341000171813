<bidvest-business-case-header>
    <span
      ngProjectAs="business-case-header-back-btn"
      [routerLink]="routes['BUSINESS_CASE'] + '/' + businessCaseId"
    >
      <button mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
  <span
    ngProjectAs="business-case-header-title"
    [matTooltip]="businessCase?.businessCaseName!"
  >
      {{ businessCase?.businessCaseName | titlecase }}
    </span>
  <span ngProjectAs="business-case-header-number">
      {{ businessCase?.referenceNumber }}
    </span>
  <span ngProjectAs="business-case-header-actions">
      @if (businessCase?.activeStageName === 'CUSTOMER_FINANCE_REVIEW' && businessCase?.activeStageStatus === 'IN_REVIEW' && isReviewer()){
        <button
          mat-stroked-button
          color="accent"
          class="mr-4"
          (click)="rejectBusinessCaseCreation()"
        >
          Reject soft services
        </button>
        <button
          mat-flat-button
          color="accent"
          (click)="approveBusinessCaseCreation()"
        >
          Approve soft services
        </button>
      }

    @if(businessCase?.activeStageName === 'CUSTOMER_FINANCE_REVIEW' && businessCase?.activeStageStatus === 'DRAFT' && isEditor()) {
      <button
        mat-flat-button
        color="accent"
        (click)="review()"
      >
          Submit for review
        </button>
    }
    </span>
</bidvest-business-case-header>

<div class="bg-white rounded-xl p-8 w-full min-h-40 shadow-md flex flex-col justify-start">
  <div>
    <img class="header-logo" src="assets/bidvest-header-logo.png" />
  </div>
  <div class="flex grid grid-col-1 gap-2" *ngIf="businessCaseId && softServiceQuote">
    <h4 class="text-blue-800 font-bold text-2xl pb-8">Soft services quote</h4>
    <div class="grid grid-cols-2 gap-2 w-[400px] py-4">
      <div>
        <p class="text-gray-800 font-semibold"> Quote date</p>
        <p class="text-gray-800 font-semibold"> Implementation date</p>
        <p class="text-gray-800 font-semibold"> Site</p>
        <p class="text-gray-800 font-semibold"> Site name</p>
        <p class="text-gray-800 font-semibold"> Region </p>
      </div>
      <div class="flex flex-col items-end">
        <p class="text-gray-400">
          {{ softServiceQuote.quoteDate }}
        </p>
        <p class="text-gray-400">
          {{ softServiceQuote.implementationDate }}
        </p>
        <p class="text-gray-400  ">
          {{ softServiceQuote.site }}
        </p>
        <p class="text-gray-400  ">
          {{ softServiceQuote.siteName }}
        </p>
        <p class="text-gray-400  ">
          {{ softServiceQuote.region }}
        </p>
      </div>
    </div>
  </div>
  <div class="mb-4">

  </div>
  <div class="py-4" *ngIf="softServiceQuote">
    <bidvest-services-quotes-table
      [softServiceQuote]="softServiceQuote"
    ></bidvest-services-quotes-table>
  </div>
  <div class="pt-4">
    <p class="footer-text">
      The above excludes the impact of Inflation or any other increases which may be agreed to between Absa and the Supplier
    </p>
    <p class="footer-text">
      The above quote  has been prepared using the Service Standards agreed between Absa and the Supplier as documented in the  Changes to the scopes of work in the
      engineering services standards (INFO 7) Record of Decision 10 December 2020.
    </p>
    <p class="footer-text">
      The above values may differ from the Change Note/Business Case Appendix 2 due to Excel day rounding versus system rounding.
    </p>
  </div>
</div>
