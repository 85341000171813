import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog';
import { ProfileSettingsService } from '../../services/profile-settings.service';
import { IProfileNotifications } from '../../models/profile-notifications';

@Component({
  selector: 'bidvest-profile-notifications-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatDialogTitle,
    MatDivider,
    MatDialogContent,
    MatSlideToggleModule,
    MatDialogActions,
    MatDialogClose
  ],
  templateUrl: './profile-notifications-settings.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './profile-notifications-settings.component.scss'
})
export class ProfileNotificationsOverlayComponent implements OnInit {
  public form!: FormGroup;
  public currentSettings!: IProfileNotifications;
  

  public constructor(
    private readonly _fb: FormBuilder,
    private notificationsService: ProfileSettingsService
  ) {
    this.form = this._fb.group({
      comments: ['', Validators.required],
      alerts: ['', Validators.required],
      label: ['', Validators.required]
    })
    
  }

  public async ngOnInit(): Promise<void> {
    this.currentSettings = await this.notificationsService.currentNotificationsSettings();
    this.populateCurrentSettings();
  }

  public async populateCurrentSettings() {
    if (this.currentSettings) {
      this.form = this._fb.group({
        comments: [this.currentSettings.alerts, Validators.required],
        alerts: [this.currentSettings.alerts, Validators.required],
        label: [this.currentSettings.label, Validators.required]
      })
    }
  }

  public toggleNotificationsSettings() {
    this.notificationsService.updateNotificationsSettings(this.form.value);
  }

  public disableButton() {
    if (this.currentSettings && this.form.value) {
      const currentNotificationsData = Object.values(this.currentSettings);
      const notificationsDataChange = Object.values(this.form.value);
      if (currentNotificationsData.length === notificationsDataChange.length
        &&
        currentNotificationsData.every((item, index) => item === notificationsDataChange[index])) {
        return true;
      } else { 
        return false;
       }
    } return true;
  }
}
