<div mat-dialog-content class="w-full bg-slate-200 p-4 justify-start">
  <bidvest-business-case-header>
    <span ngProjectAs="business-case-header-back-btn" [routerLink]="routes['BUSINESS_CASES']">
      <button mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span ngProjectAs="business-case-header-title">
      <p class="text-primary-blue">
        Business Case Name
      </p>
    </span>
    <span ngProjectAs="business-case-header-number">
      <p class="text-primary-blue">
        x6df7x
      </p>
    </span>
    <span ngProjectAs="business-case-header-actions" class="gap-2">
      <button class="underlined-button" color="accent">+ Add comment</button>
      <button mat-stroked-button class="border-button" color="accent">Reject Asset List</button>
      <button mat-flat-button class="approve-button" color="accent">Approve Asset List</button>
    </span>
  </bidvest-business-case-header>
</div>