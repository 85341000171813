<form class="grid grid-cols-2 py-4" [formGroup]="permissionsForm">
  <span
    class="col-span-2 flex flex-row justify-between items-center border-b-2 border-dashed border-slate-200"
  >
    <h4 class="font-semibold text-sm">{{ feature | uppercase }}</h4>
    <mat-checkbox labelPosition="before" [checked]="checkAllPermissions === true" (change)="selectAllPermissions()"
      >Select All</mat-checkbox
    >
  </span>

  <mat-checkbox
    class="col-span-1"
    formControlName="read"
    [checked]="checkAllPermissions === true"
    >Read</mat-checkbox
  >
  <mat-checkbox
    class="col-span-1"
    formControlName="write"
    [checked]="checkAllPermissions === true"
    >Write</mat-checkbox
  >
  <mat-checkbox
    class="col-span-1"
    formControlName="update"
    [checked]="checkAllPermissions === true"
    >Update</mat-checkbox
  >
  <mat-checkbox
    class="col-span-1"
    formControlName="delete"
    [checked]="checkAllPermissions === true"
    >Delete</mat-checkbox
  >
</form>
