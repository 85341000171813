import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IViewSoftServices } from '../models/view-soft-services';
import { IPaginationData } from '../models/pagination-data';
import { ISoftServicesBusinessCaseDetails } from '../models/soft-service-business-case-details';
import { IAddSoftServicesData } from '../models/add-soft-services-data';

@Injectable({
  providedIn: 'root'
})
export class SoftServicesService {
  private baseUrl: string = environment.apiUrl;
  private httpClient: HttpClient = inject(HttpClient);

  public viewBusinessCaseSoftServices(businessCaseId: number): Promise<Array<ISoftServicesBusinessCaseDetails>> {
    return firstValueFrom(this.httpClient.get<Array<ISoftServicesBusinessCaseDetails>>(
        `${this.baseUrl}/business-cases/${businessCaseId}/soft-services`)
    );
  }

  public viewAvailableRateCardServices(payload: IPaginationData): Promise<IViewSoftServices> {
    return firstValueFrom(this.httpClient.put<IViewSoftServices>(
      `${this.baseUrl}/business-cases/rate-cards`, payload
    ));
  }

  public addSoftService(
    payload: Array<IAddSoftServicesData>,
    businessCaseId: number
  ): Promise<void> {
    return firstValueFrom(this.httpClient.post<void>(
      `${this.baseUrl}/business-cases/${businessCaseId}/soft-services`,
      payload
    ));
  }

}
