import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IUserRoles } from '../../../models/user-roles';
import { PermissionsSelectorComponent } from '../../permissions-selector/permissions-selector.component';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CreateUserPayload } from '../../../interfaces/create-user-payload.interface';
import { UserPermission } from '../../../interfaces/user-permission.interface';
import { UserService } from '../../../services/user.service';

export interface CheckboxOptions {
  label: string;
}

@Component({
  selector: 'bidvest-new-staff-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDividerModule,
    MatInputModule,
    MatCheckboxModule,
    PermissionsSelectorComponent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogContent,
    MatDialogClose
  ],
  templateUrl: './new-staff-overlay.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './new-staff-overlay.component.scss'
})
export class NewStaffOverlayComponent implements OnInit {
  public newStaffForm!: FormGroup;
  public permissionFeatures: Array<string> = [
    'DASHBOARD',
    'USERS',
    'PROFILE',
    'RATE_CARD',
    'BUSINESS_CASE'
  ];
  public userRoles: IUserRoles[] = [];

  public constructor(
    private readonly _fb: FormBuilder,
    private readonly _toast: ToastrService,
    private readonly _loader: NgxUiLoaderService,
    private readonly _userService: UserService,
    private readonly _dialogRef: MatDialogRef<NewStaffOverlayComponent>
  ) { }

  public ngOnInit(): void {
    this.userRoles = [...this._userService.userRoles];
    this.initializeForm();
  }

  private initializeForm(): void {
    this.newStaffForm = this._fb.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      role: ['', [Validators.required]],
      password: ['', Validators.required],
      permissions: this._fb.group({
        dashboard: [
          {
            read: false,
            write: false,
            update: false,
            delete: false,
            permissionFeature: 'DASHBOARD',
            preApprove: false
          },
          Validators.required
        ],
        users: [
          {
            read: false,
            write: false,
            update: false,
            delete: false,
            permissionFeature: 'USERS',
            preApprove: false
          },
          Validators.required
        ],
        profile: [
          {
            read: false,
            write: false,
            update: false,
            delete: false,
            permissionFeature: 'PROFILE',
            preApprove: false
          },
          Validators.required
        ],
        rateCard: [
          {
            read: false,
            write: false,
            update: false,
            delete: false,
            permissionFeature: 'RATE_CARD',
            preApprove: false
          },
          Validators.required
        ],
        businessCase: [
          {
            read: false,
            write: false,
            update: false,
            delete: false,
            permissionFeature: 'BUSINESS_CASE',
            preApprove: false
          },
          Validators.required
        ]
      })
    });
  }

  public submitForm(): void {
    if (this.newStaffForm.valid) {
      this._loader.start();

      const { email, fullName, role, password, permissions } =
        this.newStaffForm.getRawValue();

      const newUserPayload: CreateUserPayload = {
        fullName,
        password,
        emailAddress: email,
        role,
        userPermissions: [
          ...(Object.values(permissions) as Array<UserPermission>)
        ]
      };

      this._userService.createNewUser(newUserPayload).subscribe({
        next: (_) => {
          this._loader.stop();
          this._toast.success('User account has been successfully created');
          this._dialogRef.close(true);
        },
        error: () => {
          this._loader.stop();
        }
      });
    }
  }

  public normalizeControlName(name: string): string {
    if (name.split('_').length > 1) {
      const splitStrings: Array<string> = name.split('_');
      return splitStrings[0]
        .trim()
        .toLowerCase()
        .concat(
          splitStrings[1]
            .charAt(0)
            .toUpperCase()
            .trim()
            .concat(
              splitStrings[1]
                .substring(1, splitStrings[1].length)
                .toLowerCase()
                .trim()
            )
        );
    } else {
      return name.toLowerCase().trim();
    }
  }

  public closeStaffOverlay() {
    this._dialogRef.close();
  }

}
