<div class="w-full flex flex-row justify-between items-center p-4">
  <h4 class="font-semibold text-red-500 inline-block">Delete Business Case</h4>

  <button mat-icon-button matDialogClose>
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div matDialogContent>
  <p class="font-semibold text-red-500">
    You're about to delete {{ data.refNo }}
  </p>
  <small>This is not a reversible ction</small>
  <mat-form-field class="w-full my-8" appearance="outline">
    <mat-label>Type in the business case title below</mat-label>
    <input type="text" matInput [(ngModel)]="businessCaseRefNo" />
  </mat-form-field>
</div>
<div matDialogActions align="start">
  <button
    mat-flat-button
    class="w-full"
    color="accent"
    [disabled]="businessCaseRefNo !== data.refNo"
    (click)="deleteBusinessCase()"
  >
    Delete
  </button>
</div>
