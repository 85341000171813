<div class="mb-12">
  <img class="header-logo" src="assets/bidvest-header-logo.png" />
</div>
<div class="grid grid-cols-12 gap-4">
  <div class="col-span-12">
    <h4 class="text-primary-blue font-bold text-2xl m-0">
      Engineering services quote
    </h4>
  </div>
  <div class="col-span-6 gap-2 py-2">
    <div class="flex flex-row justify-between">
      <p class="text-gray-800 font-semibold">Quote date</p>
      <p class="text-gray-400">dd/mm/yyyy</p>
    </div>
    <div class="flex flex-row justify-between">
      <p class="text-gray-800 font-semibold">Implementation date</p>
      <p class="text-gray-400">Placeholder text</p>
    </div>
    <div class="flex flex-row justify-between">
      <p class="text-gray-800 font-semibold">Site</p>
      <p class="text-gray-400">Placeholder text</p>
    </div>
    <div class="flex flex-row justify-between">
      <p class="text-gray-800 font-semibold">Site name</p>
      <p class="text-gray-400">Placeholder text</p>
    </div>
    <div class="flex flex-row justify-between">
      <p class="text-gray-800 font-semibold">Region</p>
      <p class="text-gray-400">Placeholder text</p>
    </div>
  </div>
</div>
<div class="mb-4"></div>
<div class="py-4">
  <bidvest-services-quotes-table></bidvest-services-quotes-table>
</div>
<div class="pt-4">
  <p class="text-primary-blue text-sm font-semibold">
    The above excludes the impact of Inflation or any other increases which may
    be agreed to between Absa and the Supplier
  </p>
  <p class="text-primary-blue text-sm font-semibold">
    The above quote has been prepared using the Service Standards agreed between
    Absa and the Supplier as documented in the Changes to the scopes of work in
    the engineering services standards (INFO 7) Record of Decision 10 December
    2020.
  </p>
  <p class="text-primary-blue text-sm font-semibold">
    The above values may differ from the Change Note/Business Case Appendix 2
    due to Excel day rounding versus system rounding.
  </p>
</div>
