import { Component, inject } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { FileUploadComponent } from '@bidvest/shared';

@Component({
  selector: 'bidvest-document-update-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogClose,
    MatDialogContent,
    FileUploadComponent,
    MatRadioModule,
    MatFormFieldModule,
    FormsModule,
    NgIf
  ],
  templateUrl: './document-update-dialog.component.html',
  styleUrl: './document-update-dialog.component.scss'
})
export class DocumentUpdateDialogComponent {
  public selectedDocumentType!: string;
  public documentTypes: Array<string> = [
    'CONSTRUCTION DRAWING',
    'IOP SCHEDULE'
  ];
  public requiredDocs: {
    cdaLayout: boolean;
    iopSchedule: boolean;
    rr03PropertyList: boolean;
  } = {
    cdaLayout: false,
    iopSchedule: false,
    rr03PropertyList: false
  };
  public readonly data = inject<{ businessCaseId: number }>(MAT_DIALOG_DATA);
}
