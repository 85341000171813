import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Input,
  InputSignal,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BusinessCaseActivityLogMessageComponent } from '../business-case-activity-log-message/business-case-activity-log-message.component';
import { IBusinessCaseActivityLogMessage } from '../../models/business-case-activity-log-message';
import { IActivityLog } from '../../interfaces/activity-log.interface';
import { FormControl, FormsModule, NgModel, Validators } from '@angular/forms';
import { BusinessCaseService } from '../../services/business-case.service';
import {
  BehaviorSubject,
  debounce,
  debounceTime,
  delay,
  firstValueFrom,
  fromEvent,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { OrderByPipe } from '@bidvest/shared';

@Component({
  selector: 'bidvest-business-case-activity-log',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    BusinessCaseActivityLogMessageComponent,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    OrderByPipe
  ],
  templateUrl: './business-case-activity-log.component.html',
  styleUrl: './business-case-activity-log.component.scss'
})
export class BusinessCaseActivityLogComponent implements OnInit, OnDestroy {
  @ViewChild('textArea')
  public textArea!: ElementRef<HTMLTextAreaElement>;
  @Input()
  public overlayRef!: OverlayRef;
  public businessCaseId: InputSignal<number | undefined> = input();
  public data!: Array<IActivityLog>;
  public newCommentInput!: string;
  public showSubmitBtn: boolean = true;

  private readonly _businessCaseService: BusinessCaseService =
    inject(BusinessCaseService);
  private readonly _destroy$: Subject<void> = new Subject();
  private readonly _loader: NgxUiLoaderService = inject(NgxUiLoaderService);
  private readonly _toastr: ToastrService = inject(ToastrService);

  public async ngOnInit(): Promise<void> {
    if (this.businessCaseId()) {
      this.data = await firstValueFrom(
        this._businessCaseService.fetchActivityLogs(
          this.businessCaseId() as number
        )
      );
    }

    this.trackTextAreaInteraction();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public submitComment(): void {
    if (this.newCommentInput.length > 0) {
      this._loader.start();
      this._businessCaseService
        .addNewComment({
          id: this.businessCaseId() as number,
          comment: this.newCommentInput
        })
        .pipe(
          switchMap((_) =>
            this._businessCaseService.fetchActivityLogs(
              this.businessCaseId() as number
            )
          )
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: (logs: Array<IActivityLog>) => {
            this.data = logs;
            this._loader.stop();
            this._toastr.success('Comment added successfully!');
            this.newCommentInput = '';
          }
        });
    }
  }

  public closeOverlay(): void {
    this.overlayRef.detach();
    this.overlayRef.dispose();
  }

  public trackTextAreaInteraction(): void {
    fromEvent(this.textArea?.nativeElement, 'keyup')
      .pipe(debounceTime(1500))
      .pipe(takeUntil(this._destroy$))
      .subscribe((val) => {
        this.textArea?.nativeElement.blur();
      });
  }
}
