<div class="rounded p-6 bg-white">
  <form [formGroup]="businessCaseForm" class="flex flex-col w-full">
    <section class="flex flex-col w-full mb-4">
      <div class="rounded w-full bg-slate-100 p-4">
        <h4 class="font-semibold">Details</h4>
      </div>

      <div class="w-full flex flex-col mb-4 pt-8">
        <mat-form-field appearance="outline" floatLabel="always" class="w-full">
          <mat-label
            matTooltip="Info about the action"
            class="flex flex-row justify-center"
            >Select Business Case Action Type
            <mat-icon class="ml-2">info</mat-icon></mat-label
          >
          <mat-select formControlName="businessCaseActionType">
            @for (type of businessCaseActionTypes; track type) {
            <mat-option [value]="type">{{
              type.trim().split('_').join(' ')
            }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="w-full mt-8 mb-4"
      >
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row items-center"
          >Business case name
          <mat-icon class="ml-2">info</mat-icon>
        </mat-label>
        <input type="text" matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always" class="w-full">
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row items-center"
          >Implementation Date
          <mat-icon class="ml-2">info</mat-icon>
        </mat-label>

        <input
          matInput
          [matDatepicker]="picker"
          formControlName="implementationDate"
        />

        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always" class="w-full">
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row items-center"
          >Business case description
          <mat-icon class="ml-2">info</mat-icon>
        </mat-label>
        <textarea matInput rows="5" formControlName="description"></textarea>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="w-full mb-4"
      >
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row items-center"
          >Town
          <mat-icon class="ml-2">info</mat-icon>
        </mat-label>
        <input type="text" matInput formControlName="town" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="w-full mb-4"
      >
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row items-center"
          >Province
          <mat-icon class="ml-2">info</mat-icon>
        </mat-label>
        <input type="text" matInput formControlName="province" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="w-full mb-4"
      >
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row items-center"
          >Building number
          <mat-icon class="ml-2">info</mat-icon>
        </mat-label>
        <input type="text" matInput formControlName="buildingNumber" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="w-full mb-4"
      >
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row items-center"
          >Outlet Type
          <mat-icon class="ml-2">info</mat-icon>
        </mat-label>
        <input type="text" matInput formControlName="outletType" />
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always" class="w-full">
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row items-center"
          >Outlet
          <mat-icon class="ml-2">info</mat-icon>
        </mat-label>
        <input type="text" matInput formControlName="outlet" />
      </mat-form-field>
    </section>

    <mat-divider></mat-divider>

    <section class="flex flex-col w-full my-4">
      <div class="rounded w-full bg-slate-100 p-4">
        <h4 class="font-semibold">Uploads</h4>
      </div>

      <div class="flex flex-row justify-start py-4">
        @if (!isReadOnly()) { @for (document of
        businessCaseDetails()?.documents; track $index) {
        <div class="flex flex-col mr-4">
          <bidvest-file-upload
            (uploadSuccess)="requiredDocs.cdaLayout = true"
            [updateMode]="true"
            [allowedMimeTypes]="['.pdf', '.png', '.docx', '.jpg', '.jpeg']"
            [businessCaseId]="businessCaseDetails()?.id!"
            [url]="'documents/' + document.documentType + '/' + document.id"
            [required]="true"
          ></bidvest-file-upload>
          <small class="font-semibold">CDA Layout</small>
        </div>
        } } @else { @for (document of businessCaseDetails()?.documents; track
        $index) {
        <div class="flex flex-col mr-4 border-2 rounded p-4 gap-y-6 max-w-64">
          <span class="flex flex-col">
            <small class="font-semibold text-xs p-1 bg-slate-200 rounded mb-2"
              >Document Name</small
            >
            <small>{{ document.documentName }}</small>
          </span>

          <span class="flex flex-col">
            <small class="font-semibold text-xs p-1 bg-slate-200 rounded mb-2"
              >Document Type</small
            >
            <small class="text-xs">{{
              document.documentType.split('_').join(' ')
            }}</small>
          </span>

          <button
            mat-flat-button
            color="accent"
            (click)="downloadDocument(document)"
          >
            Download
          </button>
        </div>
        } }
      </div>
    </section>

    <mat-divider></mat-divider>

    <section class="flex flex-col w-full mt-4">
      <div class="rounded w-full bg-slate-100 p-4">
        <h4 class="font-semibold">Collaborators</h4>
      </div>

      <div class="w-full flex flex-col mb-4">
        <span class="my-4">
          <h4 class="text-lg">Stages</h4>
        </span>
      </div>

      @if (businessCaseDetails()?.collaborators) { @for (stage of
      businessCaseStages; track $index) {

      <div class="grid grid-cols-12 gap-4 mt-8">
        <div class="col-span-4">
          <h4 class="font-bold">
            {{ stage.stageName.split('_').join(' ') }}
          </h4>
        </div>

        <div class="col-span-4 flex flex-col">
          <p class="text-sm font-semibold">Stage Owner</p>
          <mat-chip-set class="w-full">
            <mat-chip>{{ getStageOwner(stage) }}</mat-chip>
          </mat-chip-set>
        </div>

        <div class="col-span-4 flex flex-col">
          <p class="text-sm font-semibold">Reviewers</p>
          <mat-chip-set class="w-full">
            @for (reviewer of bcReviewers[stage.stageName]; track reviewer) {
            <mat-chip>{{ reviewer }}</mat-chip>
            }
          </mat-chip-set>
        </div>
      </div>

      <mat-divider></mat-divider>

      } } @else { @for (collaborator of businessCaseDetails()?.collaborators;
      track $index) {

      <bidvest-collaborators-selector
        [businessCaseAccounts]="businessCaseAccounts"
        [collaboratorsData]="collaborator"
        [isReadOnly]="businessCaseDetails()?.activeStageStatus === 'IN_REVIEW'"
        (collaboratorsSelection)="getCollaboratorSelection($event)"
      ></bidvest-collaborators-selector>

      <mat-divider></mat-divider>
      } }
    </section>
  </form>
</div>
