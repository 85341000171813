<div class="py-2">
  <p class="font-semibold text-primary-blue pt-2 text-2xl">Soft Services Quote</p>
  <p class="text-gray-400 text-sm pb-4">Approve soft services added to this business case</p>
</div>
<table mat-table [dataSource]="rateCardData" appearance="outline">


  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Code</th>
    <td mat-cell *matCellDef="let element">{{ element.code }}</td>
  </ng-container>

  <ng-container matColumnDef="serviceName">
    <th mat-header-cell *matHeaderCellDef>Service name</th>
    <td mat-cell *matCellDef="let element">{{ element.serviceName }}</td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let element">{{ element.category }}</td>
  </ng-container>

  <ng-container matColumnDef="subCategory">
    <th mat-header-cell *matHeaderCellDef>Sub Category</th>
    <td mat-cell *matCellDef="let element">{{ element.subCategory }}</td>
  </ng-container>

  <ng-container matColumnDef="unitCost">
    <th mat-header-cell *matHeaderCellDef>Unit cost</th>
    <td mat-cell *matCellDef="let element">
        {{ element.unitCost }}
    </td>
  </ng-container>

  <ng-container matColumnDef="unitOfMeasure">
    <th mat-header-cell *matHeaderCellDef>UOM</th>
    <td mat-cell *matCellDef="let element">
        {{ element.unitOfMeasure }}
    </td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef>Quantity</th>
    <td mat-cell *matCellDef="let element">0.00{{ element.quantity }}</td>
  </ng-container>

  <mat-paginator aria-label="Select page"> </mat-paginator>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>