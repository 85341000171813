import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { firstValueFrom } from 'rxjs';
import { IViewStaffData } from '../models/view-staff-data';
import { IUpdateStaffData } from '../models/update-staff-data';
import { IEngineeringServciesTabData } from '../models/engineering-tab-data';

@Injectable({
  providedIn: 'root'
})
export class EngineeringServiceTableService {
  private baseUrl: string = environment.apiUrl;
  private httpClient: HttpClient = inject(HttpClient);
  private _mockEngineeringData = 'assets/dummy-data/engineering-table-mock.json'

  public async getEngineeringData(): Promise<IEngineeringServciesTabData[]> {
    return  firstValueFrom(this.httpClient.get<IEngineeringServciesTabData[]>(`${this._mockEngineeringData}`))
  }

}
