import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { MatRadioModule } from '@angular/material/radio';
import { BusinessCaseService } from '../../services/business-case.service';
import { IBusinessCaseOverview } from '../../interfaces/business-case-overview.interface';
import { UserAvatarComponent } from '@bidvest/shared';
import { BusinessCaseStageIndicatorComponent } from '../business-case-stage-indicator/business-case-stage-indicator.component';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';

@Component({
  selector: 'bidvest-business-case-overview-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatRadioModule,
    UserAvatarComponent,
    BusinessCaseStageIndicatorComponent
  ],
  templateUrl: './business-case-overview-panel.component.html',
  styleUrl: './business-case-overview-panel.component.scss'
})
export class BusinessCaseOverviewPanelComponent {
  @Input()
  public stages: Array<IBusinessCaseStageConfig> = [];

  @Input()
  public overview: IBusinessCaseOverview | null = null;

  @Input()
  public get activeStageName(): string {
    return this._activeStageName;
  }

  public set activeStageName(value: string | undefined) {
    if (value) {
      this._activeStageName = value;
    }
  }

  private _activeStageName!: string;

  public isActiveStage(stage: string): boolean {
    return this._activeStageName === stage;
  }

  public getStageOwnerAbbreviatedName(): string {
    return this.overview?.activeStageOwner
      .split(' ')[0]
      .charAt(0)
      .concat(this.overview.activeStageOwner.split(' ')[1].charAt(0))
      .toUpperCase() as string;
  }
}
