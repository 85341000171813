import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { BusinessCaseOverviewPanelComponent } from '../business-case-overview-panel/business-case-overview-panel.component';
import { BusinessCaseActivityLogComponent } from '../business-case-activity-log/business-case-activity-log.component';
import { BusinessCaseService } from '../../services/business-case.service';
import { forkJoin, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { BusinessCaseDetailsFormComponent } from '../business-case-details-form/business-case-details-form.component';
import { BusinessCaseHeaderComponent } from '../../../../../../../shared/src/lib/shared/components/business-case-header/business-case-header.component';
import { IBusinessCaseOverview } from '../../interfaces/business-case-overview.interface';
import { IActivityLog } from '../../interfaces/activity-log.interface';
import { ToastrService } from 'ngx-toastr';
import { IRateCardDataType } from '../../models/rate-card-data';
import { ActivatedRoute, ParamMap, RouterLink } from '@angular/router';
import { backOfficeRouteKeys } from '@bidvest/shared';
import { AssetDrawingViewComponent } from '../asset-drawing-view/asset-drawing-view.component';
import { VerifyAssetTableComponent } from '../tables/verify-asset-table/verify-asset-table.component';

@Component({
  selector: 'bidvest-asset-list-read-only',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogClose,
    MatDialogContent,
    MatButtonModule,
    MatTabsModule,
    MatDividerModule,
    BusinessCaseDetailsFormComponent,
    BusinessCaseOverviewPanelComponent,
    BusinessCaseActivityLogComponent,
    BusinessCaseHeaderComponent,
    RouterLink,
    AssetDrawingViewComponent,
    VerifyAssetTableComponent
  ],
  templateUrl: './asset-list-read-only.component.html',
  styleUrl: './asset-list-read-only.component.scss'
})
export class AssetListReadOnlyComponent implements OnInit {
  public businessCase: IBusinessCaseDetails | null = null;
  public businessCaseStages: Array<BusinessCaseStage> = [];
  public routes: { [key: string]: string } = backOfficeRouteKeys;

  private _loader: NgxUiLoaderService = inject(NgxUiLoaderService);
  private _toastr: ToastrService = inject(ToastrService);
  private _destroy$: Subject<void> = new Subject();
  private _route: ActivatedRoute = inject(ActivatedRoute);

  constructor(private _businessCaseService: BusinessCaseService) {}

  ngOnInit(): void {
    this._loader.start();

    this._route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          const id: number = params.get('id') as unknown as number;
          return this._businessCaseService.fetchBusinessCaseById(+id);
        })
      )
      .pipe(takeUntil(this._destroy$))
      .pipe(
        tap((data: IBusinessCaseDetails | undefined) => {
          if (data) {
            this.businessCase = data;
            this.businessCaseStages = data.collaborators.map((stage) => ({
              id: stage.id,
              stageName: stage.businessCaseStageName
            }));
          }
        })
      );

    this._loader.stop();
  }
}
