import { CommonModule } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { CustomToastrService } from "../../../services/custom-toastr.service";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: 'bidvest-success-toast-component',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './success-toastr.component.html',
  providers: [],
  styleUrl: './success-toastr.component.scss'
})
export class SuccessToastComponent {
  public constructor(
    @Inject(MAT_SNACK_BAR_DATA) public description: string,
    private toastrService: CustomToastrService
  ) { }

public closeOnClick() {
  this.toastrService.closeToastr();
}

}
