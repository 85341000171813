<div class="overlay-container">
  <div class="flex flex-row justify-between items-center">
    <div mat-dialog-title>
      <h2 class="font-semibold text-primary-blue text-2xl pl-4">
        Create New Rate Cards
      </h2>
    </div>
    <mat-icon class="cursor-pointer text-gray-500" (click)="closeRateCardOverlay()">close</mat-icon>
  </div>
  <div mat-dialog-content class="mt-4">
    <form [formGroup]="form" class="mt-4">
      <mat-form-field class="w-full mb-4">
        <mat-label>Service name</mat-label>
        <input type="text" matInput placeholder="Service name" formControlName="serviceName" />
      </mat-form-field>

      <mat-form-field class="w-full mb-4">
        <mat-label>Service description</mat-label>
        <input type="text" matInput placeholder="Service description" formControlName="serviceDescription" />
      </mat-form-field>

      <div class="gap-4 mb-4">
        <mat-form-field class="w-full mb-4">
          <mat-label>Category</mat-label>
          <mat-select formControlName="category">
            @for (category of categories; track category) {
            <mat-option [value]="category.viewValue">{{ category.value }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full mb-4 ">
          <mat-label>Sub-category</mat-label>
          <mat-select formControlName="subCategory">
            @for (subCategory of subCategories; track subCategory) {
            <mat-option [value]="subCategory.viewValue">{{ subCategory.value }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field class="w-full mb-4 col-span-6">
        <mat-label>Rate card group</mat-label>
        <mat-select formControlName="rateCardGroup">
          @for (rateCardgroup of rateCardGroup; track rateCardgroup) {
          <mat-option [value]="rateCardgroup.viewValue">{{ rateCardgroup.value }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <div class="mb-4">
        <mat-form-field class="w-full mb-4">
          <mat-label>Unit cost</mat-label>
          <input type="text" matInput placeholder="R" defaultValue="R" formControlName="unitCost" />
        </mat-form-field>

        <mat-form-field class="w-full mb-4">
          <mat-label>UOM</mat-label>
          <mat-select formControlName="unitOfMeasure">
            @for (unitsOfMeaurements of unitsOfMeaurement; track unitsOfMeaurements) {
            <mat-option [value]="unitsOfMeaurements.viewValue">{{ unitsOfMeaurements.value }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field class="w-full mb-4">
        <mat-label>Notes</mat-label>
        <textarea type="text" matInput placeholder="Notes" formControlName="notes"></textarea>
      </mat-form-field>

      <mat-form-field class="w-full mb-4">
        <mat-label>Vendor name</mat-label>
        <input type="text" matInput placeholder="Vendor name" formControlName="vendorName" />
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always" class="w-full mb-4">
        <mat-label matTooltip="Select the date until valid" class="flex flex-row items-center">Valid unitl
          <mat-icon class="ml-2">info</mat-icon>
        </mat-label>

        <input matInput [matDatepicker]="picker" formControlName="validTillDate" />

        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <div>
        <button type="button" (click)="addNewRateCard()" mat-flat-button color="accent" [disabled]="!form.valid"
          class="bg-orange-500 rounded-lg px-6 py-2 flex flex-row justify-center items-center w-full text-white font-bold">
          Create new rate card
        </button>
      </div>
    </form>
  </div>
</div>