import { Route } from '@angular/router';
import { BusinessCasesComponent } from './features/views/business-cases/business-cases.component';
import { SoftServicesComponent } from './features/views/soft-services/soft-services.component';
import { RateCardLayoutComponent } from './features/views/rate-card-layout/rate-card-layout.component';
import { AssetListSeenOverlayComponent } from './features/components/overlays/asset-list-seen/asset-list-seen-overlay.component';
import { AssetListUnseenOverlayComponent } from './features/components/overlays/asset-list-unseen/asset-list-unseen-overlay.component';
import { AddToAssetListOverlayComponent } from './features/components/overlays/add-to-asset-list/add-to-asset-list-overlay.component';
import { VerifyAssetListComponent } from './features/views/verify-asset-list/verify-asset-list.component';
import { CreateRateCardOverlayComponent } from './features/components/overlays/create-rate-card-overlay/create-rate-card-overlay.component';
import { AddSoftServiceOverlayComponent } from './features/components/overlays/add-soft-services-overlay/add-soft-services-overlay.component';
import { UpdateRateCardOverlayComponent } from './features/components/overlays/update-rate-card-overlay/update-rate-card-overlay.component';
import { ManageStaffOverlayComponent } from './features/components/overlays/manage-staff-details-overlay/manage-staff-details-overlay.component';
import { NewStaffOverlayComponent } from './features/components/overlays/new-staff-overlay/new-staff-overlay.component';
import { StaffComponent } from './features/views/staff/staff.component';
import { SoftServicesQuoteLayoutComponent } from './features/views/soft-service-quotes-layout/soft-service-quotes-layout.component';
import { EngineeringServicesQuotesComponent } from './features/views/engineering-services-quotes/engineering-service-quotes.component';
import { AddingEngineeringQuotesTableComponent } from './features/components/tables/add-engineering-quotes-table/add-engineering-quotes-table.component';
import { StaffSoftDeleteOverlayComponent } from './features/components/overlays/staff-soft-delete-overlay/staff-soft-delete-overlay.component';
import { SoftServicesReadOnlyComponent } from './features/components/soft-services-read-only/soft-services-read-only.component';
import { ReasonForRejectionOverlayComponent } from './features/components/dialogs/reason-for-rejection-overlay/reason-for-rejection-overlay.component';
import { AssetListReadOnlyComponent } from './features/components/asset-list-read-only/asset-list-read-only.component';
import { AccountSettingsFormComponent } from './features/components/overlays/profile-settings-overlay/profile-settings-overlay.component';
import { ConfirmRateCardTableComponent } from './features/views/confirm-rate-card-link/confirm-rate-card-link.component';
import { AssetReplaceOverlayComponent } from './features/components/overlays/asset-replacing-overlay/asset-replacing-overlay.component';

export const featuresRoutes: Route[] = [
  { path: 'business-cases', component: BusinessCasesComponent },
  {
    path: 'adding-engineering-services',
    component: AddingEngineeringQuotesTableComponent
  },
  { path: 'confirm-rate-card-link', component: ConfirmRateCardTableComponent },
  { path: 'soft-services', component: SoftServicesComponent },
  { path: 'staffs', component: StaffComponent },
  { path: 'rate-cards', component: RateCardLayoutComponent },
  { path: 'asset-list-seen', component: AssetListSeenOverlayComponent },
  { path: 'asset-list-unseen', component: AssetListUnseenOverlayComponent },
  { path: 'add-to-asset-list', component: AddToAssetListOverlayComponent },
  { path: 'verify-asset-list', component: VerifyAssetListComponent },
  { path: 'create-rate-card', component: CreateRateCardOverlayComponent },
  { path: 'update-rate-card', component: UpdateRateCardOverlayComponent },
  { path: 'add-soft-services/:id', component: AddSoftServiceOverlayComponent },
  { path: 'new-staff-overlay', component: NewStaffOverlayComponent },
  { path: 'manage-staff-overlay', component: ManageStaffOverlayComponent },
  { path: 'profile-settings-overlay', component: AccountSettingsFormComponent },
  { path: 'asset-replace-overlay', component: AssetReplaceOverlayComponent },
  {
    path: 'soft-delete-staff-overlay',
    component: StaffSoftDeleteOverlayComponent
  },
  {
    path: 'bidvest-soft-service-read-only/:id',
    component: SoftServicesReadOnlyComponent
  },
  {
    path: 'bidvest-reason-for-rejection-overlay',
    component: ReasonForRejectionOverlayComponent
  },
  {
    path: 'bidvest-asset-list-read-only',
    component: AssetListReadOnlyComponent
  },
  {
    path: 'engineering-services-quote',
    component: EngineeringServicesQuotesComponent
  },
  {
    path: 'soft-services-quote/:id',
    component: SoftServicesQuoteLayoutComponent
  },
  {
    path: 'staff-soft-delete-overlay',
    component: StaffSoftDeleteOverlayComponent
  }
];
