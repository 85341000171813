import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import { ISapId } from '../../../models/sap-id';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { CustomToastrService } from '../../../services/custom-toastr.service';

@Component({
  selector: 'bidvest-add-to-asset-list-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule, 
    MatDatepickerModule,
    MatDialogClose,
    MatDialogActions,
    MatDividerModule
  ],
  templateUrl: './add-to-asset-list-overlay.component.html',
  providers: [],
  styleUrl: './add-to-asset-list-overlay.component.scss'
})
export class AddToAssetListOverlayComponent implements OnInit {
  public form!: FormGroup;
  
  sapIdData: ISapId[] = [
    {value: '0', sapId: '12345678'},
    {value: '1', sapId: '2345678'},
    {value: '2', sapId: '345678'},
    {value: '3', sapId: '45678'},
  ]
  
  public constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {},
    private readonly _fb: FormBuilder,
    private readonly _toast: CustomToastrService,
    private readonly _router: Router,
    private  _dialogRef: MatDialogRef<AddToAssetListOverlayComponent>,
  ) { }

  public ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.form = this._fb.group({
      sapId: ['', [Validators.required]],
      sapName: ['', [Validators.required]],
      description: ['', [Validators.required]],
      highLevelDescription: ['R', [Validators.required]],
    });
  }

  public closeAssetList() {
    this._dialogRef.close();    
  }  

}
