<div>

<div class="mt-8">
    <div>
        <div class="flex justify-between gap-4  items-center bg-white py-4">
            <div class="flex gap-4 justify-center">
                <form class="max-w-sm">
                    <mat-form-field class="search-bar" appearance="outline">
                        <mat-label class="search-bar-label">Search</mat-label>
                        <input style="font-size: 12px" type="text" matInput placeholder="Search"
                            name="searchTerm" />
                        <mat-icon class="white-icon" matSuffix>search</mat-icon>
                    </mat-form-field>
                </form>

                <div class="flex items-center gap-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Filter</mat-label>
                        <mat-select>
                            <mat-option>filter</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <a
                class="text-sm items-center justify-center font-semibold font-montserrat ms-center cursor-pointer font-bold text-primary-blue hover:text-primary-orange">
                <div class="flex-row flex items-center justify-center gap-2">

                    <button class="add-comment-button">
                        <mat-icon>add </mat-icon>
                        Addd comment
                    </button>
                    <button class="request-button">
                        Request for quote
                    </button>
                </div>
            </a>
        </div>
    </div>
</div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" appearance="outline">

    <ng-container matColumnDef="checkbox">

      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox>
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="sapId">
      <th mat-header-cell *matHeaderCellDef>SAP ID </th>
      <td mat-cell *matCellDef="let element"> {{element.sapId}} </td>
    </ng-container>

    <ng-container matColumnDef="sapName">
      <th mat-header-cell *matHeaderCellDef>SAP Name </th>
      <td mat-cell *matCellDef="let element"> {{element.sapName}} </td>
    </ng-container>

    <ng-container matColumnDef="buildingId">
      <th mat-header-cell *matHeaderCellDef>Building ID</th>
      <td mat-cell *matCellDef="let element"> {{element.buildingId}} </td>
    </ng-container>

    <ng-container matColumnDef="highLevelDescription">
      <th mat-header-cell *matHeaderCellDef>High level description</th>
      <td mat-cell *matCellDef="let element"> {{element.highLevelDescription}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <ng-container matColumnDef="spaceType">
      <th mat-header-cell *matHeaderCellDef>Space type</th>
      <td mat-cell *matCellDef="let element"> {{element.spaceType}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>