<form [formGroup]="form" class="mx-6 mt-6">
  <h4 class="font-semibold text-primary-blue text-xl px-8 pb-6">
    Change password
  </h4>
  <div class="px-6 w-full">
    <mat-form-field class="w-full flex" appearance="outline">
      <mat-label>Current password</mat-label>
      <input type="text" matInput placeholder="Service name" formControlName="currentPassword" />
    </mat-form-field>
    <mat-form-field class="w-full flex" appearance="outline">
      <mat-label>New password</mat-label>
      <input type="text" matInput placeholder="Service name" formControlName="newPassword" />
    </mat-form-field>
    <mat-form-field class="w-full flex" appearance="outline">
      <mat-label>Confirm password</mat-label>
      <input type="text" matInput placeholder="Service name" formControlName="confirmPassword" />
    </mat-form-field>
    <p [routerLink]="routes['FORGOT_PASSWORD']" class="text-orange-400 cursor-pointer underline pb-8 text-xl p-4">

      Forgot password?
    </p>
    <mat-divider></mat-divider>
    <div class="pt-8">
      <button mat-flat-button color="accent" [disabled]="!form.valid"
        class="w-full">
        Save
      </button>
    </div>
  </div>
</form>