import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SoftServicesData } from '../../../models/soft-services-data';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import {
  backOfficeRouteKeys,
  BusinessCaseHeaderComponent
} from '@bidvest/shared';
import { MatDivider } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { SoftServicesService } from '../../services/soft-services.service';
import { BusinessCaseService } from '../../services/business-case.service';
import { RateCardsService } from '../../services/rate-cards.service';

const ELEMENT_DATA: Array<SoftServicesData> = [
  {
    id: 1,
    code: 1,
    serviceName: 'Airconditioning',
    category: 'soft service',
    subCategory: 'HVAC',
    unitCost: 100,
    uom: 'm2',
    quantity: '50'
  },
  {
    id: 2,
    code: 2,
    serviceName: 'Airconditioning',
    category: 'soft service',
    subCategory: 'HVAC',
    unitCost: 100,
    uom: 'm2',
    quantity: '65'
  },
  {
    id: 3,
    code: 3,
    serviceName: 'Airconditioning',
    category: 'soft service',
    subCategory: 'HVAC',
    unitCost: 100,
    uom: 'm2',
    quantity: '44'
  }
];

@Component({
  selector: 'bidvest-soft-services',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSelectModule,
    BusinessCaseHeaderComponent,
    RouterLink
  ],
  templateUrl: './soft-services.component.html',
  styleUrl: './soft-services.component.scss'
})
export class SoftServicesComponent implements OnInit {
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public softServicesData!: any;
  public displayedColumns: Array<string> = [
    'checkbox',
    'code',
    'serviceName',
    'category',
    'subCategory',
    'unitCost',
    'uom',
    'quantity'
  ];
  public dataSource = new MatTableDataSource();
   public size: number = 10;
  public searchValue: string | null = null;
  public categoryValue: string = '';
  public resultsLength: number = 0;

  constructor(
    private readonly _softServicesService: SoftServicesService,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _rateCardsService: RateCardsService
  ) {}

  viewAllSoftServices() {
    // const softServicesData = this._softServicesService.viewAllSoftServices();
  }

  addSelectedSoftService(data: { rateCardId: number; quantity: number }) {
    console.log(data);
    // this._softServicesService.addSoftService(data);
  }

  ngOnInit(): void {
    this._rateCardsService.fetchRateCards({
      currentPage: 1,
      category: this.categoryValue,
      size: this.size,
      searchTerm: this.searchValue
    });
    // this._businessCaseService.
    // this.viewAllSoftServices();
  }
}
