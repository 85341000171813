import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { IEngineeringTableData } from '../../../models/enigneering-table-data';

const ELEMENT_DATA: IEngineeringTableData[] = [
  { sapId: '0982345',  sapName: 'Luke Skywalker', buildingId: '0123456', highLevelDescription: 'highLevelDescription', description: 'Description', spaceType: 'type of space', status: 'In progress' },
  { sapId: '0982345',  sapName: 'Luke Skywalker', buildingId: '0123456', highLevelDescription: 'highLevelDescription', description: 'Description', spaceType: 'type of space', status: 'In progress' },
  { sapId: '0982345',  sapName: 'Luke Skywalker', buildingId: '0123456', highLevelDescription: 'highLevelDescription', description: 'Description', spaceType: 'type of space', status: 'In progress' },
];



@Component({
  selector: 'bidvest-adding-engineering-table',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule, 
    MatTableModule,
    FormsModule
  ],
  templateUrl: './add-engineering-quotes-table.component.html',
  styleUrl: './add-engineering-quotes-table.component.scss'
})
export class AddingEngineeringQuotesTableComponent {
  displayedColumns: string[] = 
  ['checkbox','sapId', 'sapName', 'buildingId', 'highLevelDescription', 'description', 'spaceType', 'status'];
  dataSource = ELEMENT_DATA;
  
}
