<div class="bg-gray-100 rounded-xl p-4 min-h-12 flex flex-col justify-start">
  <div class="flex flex-row items-center mb-4">
    <mat-form-field>
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input
        [formGroup]="filterDateRange"
        [rangePicker]="picker"
      >
        <input matStartDate formControlName="from" placeholder="Start date" />
        <input matEndDate formControlName="to" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker
        #picker
        panelClass="datepicker-bg"
      ></mat-date-range-picker>

      @if (filterDateRange.controls.from.hasError('matStartDateInvalid')) {
      <mat-error>Invalid start date</mat-error>
      } @if (filterDateRange.controls.to.hasError('matEndDateInvalid')) {
      <mat-error>Invalid end date</mat-error>
      }
    </mat-form-field>
  </div>

  <div class="w-full grid grid-flow-row grid-cols-5 gap-4">
    <span
      class="col-span-1 rounded p-4 flex flex-col justify-center hover:bg-gray-200 cursor-pointer"
    >
      <h4 class="text-base font-normal text-gray-500">Total</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalCases
      }}</span>
    </span>
    <span
      class="col-span-1 border-l-2 rounded p-4 flex flex-col justify-center hover:bg-gray-200 cursor-pointer"
    >
      <h4 class="text-base font-normal text-gray-500">Ongoing</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalInProgressCases
      }}</span>
    </span>
    <span
      class="col-span-1 border-l-2 rounded p-4 flex flex-col justify-center hover:bg-gray-200 cursor-pointer"
    >
      <h4 class="text-base font-normal text-gray-500">Completed</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalCompletedCases
      }}</span>
    </span>
    <span
      class="col-span-1 border-l-2 rounded p-4 flex flex-col justify-center hover:bg-gray-200 cursor-pointer"
      [ngClass]="data.totalOverdueCases > 0 ? 'overdue-panel' : ''"
      (click)="filterOverdueBusinessCases()"
    >
      <h4 class="text-base font-normal text-gray-500">Overdue</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalOverdueCases
      }}</span>
    </span>
    <span
      class="col-span-1 border-l-2 rounded p-4 flex flex-col justify-center hover:bg-gray-200 cursor-pointer"
      [ngClass]="data.totalDelayedCases > 0 ? 'delayed-panel' : ''"
      (click)="filterDelayedBusinessCases()"
    >
      <h4 class="text-base font-normal text-gray-500">Delayed</h4>
      <span class="text-orange-400 font-bold text-2xl">{{
        data.totalDelayedCases
      }}</span>
    </span>
  </div>
</div>
