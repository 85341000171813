import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileUploadComponent } from '@bidvest/shared';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { IBusinessCaseAccount } from '../../interfaces/collaborator.interface';
import { BusinessCaseCollaboratorInputComponent } from '../business-case-collaborator-input/business-case-collaborator-input.component';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { CollaboratorsSelectorComponent } from '../collaborators-selector/collaborators-selector.component';
import { Collaborator } from '../../interfaces/business-case-details.interface';
import { IStageCollaborationInput } from '../../interfaces/collaborator-input.interface';

@Component({
  selector: 'bidvest-new-business-case-form',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    FileUploadComponent,
    MatOptionModule,
    MatSelectModule,
    CollaboratorsSelectorComponent
  ],
  templateUrl: './new-business-case-form.component.html',
  styleUrl: './new-business-case-form.component.scss',
  providers: [provideMomentDateAdapter()],
  exportAs: 'newBusinessCase'
})
export class NewBusinessCaseFormComponent implements OnInit {
  @Input()
  public collaborators!: Array<IBusinessCaseAccount>;

  @Input()
  public stages: Array<BusinessCaseStage> = [];

  @Input()
  public businessCaseId!: number;

  public businessCaseForm!: FormGroup;

  public initialCollaboratorData: Collaborator = {
    id: 0,
    businessCaseStageName: '',
    ownerId: 0,
    reviewerIds: []
  };

  public selectedCollaborators: Array<Collaborator> = [];

  public requiredDocs: {
    cdaLayout: boolean;
    iopSchedule: boolean;
    rr03PropertyList: boolean;
  } = {
    cdaLayout: false,
    iopSchedule: false,
    rr03PropertyList: false
  };

  public businessCaseActionTypes: Array<string> = [
    'TERMINATION',
    'DOWN_SELL',
    'UPSELL',
    'NEW_BUILDING'
  ];

  private readonly _fb: FormBuilder = inject(FormBuilder);

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.businessCaseForm = this._fb.group({
      businessCaseActionType: ['', Validators.required],
      name: ['', Validators.required],
      implementationDate: [new Date(), Validators.required],
      description: ['', Validators.required],
      town: ['', Validators.required],
      province: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      outletType: ['', Validators.required],
      outlet: ['', Validators.required],
      collaborators: this._fb.group({})
    });

    const collaborators = this.businessCaseForm.controls[
      'collaborators'
    ] as FormGroup;

    for (let stage of this.stages) {
      collaborators.addControl(
        stage.stageName,
        new FormControl({ owner: 0, reviewers: [] }, Validators.required)
      );
    }
  }

  public getCollaboratorSelection(val: IStageCollaborationInput) {
    const stageSelections = this.selectedCollaborators.find(
      (data) => data.businessCaseStageName === val.businessCaseStageName
    );
    if (stageSelections) {
      stageSelections.ownerId = val.ownerId;
      stageSelections.reviewerIds = [...val.reviewerIds];
      stageSelections.id = val.id;
    } else {
      this.selectedCollaborators.push(val);
    }
  }
}
