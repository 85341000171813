export enum UnitsOfMeasurement {
    EACH = 'EACH',
    KM = 'KM',
    HOUR = 'HOUR',
    WEEK = 'WEEK',
    MONTH =  'MONTH',
    SQM = 'SQM',
    BALE = 'BALE'
  }
  
  
