import { CommonModule } from '@angular/common';
import { Component, inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { AssetListService } from '../../../services/asset-list.service';
import { MatDialog } from '@angular/material/dialog';
import { AssetListSeenOverlayComponent } from '../../overlays/asset-list-seen/asset-list-seen-overlay.component';
import { AssetListUnseenOverlayComponent } from '../../overlays/asset-list-unseen/asset-list-unseen-overlay.component';
import { BusinessCaseBuildingEquipmentResultModel, IEquipmentRetrievalModel } from '../../../models/asset-list-equipment';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AssetDrawingViewComponent } from '../../asset-drawing-view/asset-drawing-view.component';
import { AssetDisciplinesEnum } from '../../../enums/asset-disciplines.enum';
import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BusinessCaseActivityLogComponent } from '../../business-case-activity-log/business-case-activity-log.component';
import { IBusinessCaseDetails } from '../../../interfaces/business-case-details.interface';
import { log } from 'node:console';



@Component({
  selector: 'bidvest-verification-table',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    FormsModule,
    AssetDrawingViewComponent
  ],
  templateUrl: './verify-asset-table.component.html',
  styleUrl: './verify-asset-table.component.scss'
})
export class VerifyAssetTableComponent implements OnInit, OnChanges {

  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  private readonly _route: ActivatedRoute = inject(ActivatedRoute);
  public filterOptions = Object.values(AssetDisciplinesEnum);

  public searchTerm: string = '';
  public filterValues: string = '';
  public businessCase: IBusinessCaseDetails | null = null;
  public pageLength: number = 0;

  public dataSource = new MatTableDataSource<IEquipmentRetrievalModel>([]);
  public data: BusinessCaseBuildingEquipmentResultModel = {
    total: 0,
    totalPages: 0,
    currentPage: 0,
    equipment: []
  };

  constructor(
    public assetListService: AssetListService,
    private readonly _seenAssetOverlay: MatDialog,
    private readonly _overlay: Overlay

  ) { }

  public async ngOnInit() {
    await this.getEquipmentData();
    this.dataSource = new MatTableDataSource<IEquipmentRetrievalModel>(this.data.equipment);
    this.dataSource.paginator = this.paginator;
    console.log(this.paginator);
    
    this.pageLength = this.data.total;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = changes['data'].currentValue;
  }

  public async getEquipmentData() {
    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    const businessCaseId = routeParams.get('id') as unknown as number;
    this.data = await this.assetListService.getAssetListEquipmentData({
      id: businessCaseId,
      page: this.paginator.pageIndex,
      size: 100,
      filter: this.filterValues,
      searchTerm: this.searchTerm
    });
  }

  public async searchOnClick() {
    await this.getEquipmentData();
    this.dataSource = new MatTableDataSource<IEquipmentRetrievalModel>(this.data.equipment);
  }

  public async applyFilter(event: string) {
    this.filterValues = event;
    await this.getEquipmentData();
    this.dataSource = new MatTableDataSource<IEquipmentRetrievalModel>(this.data.equipment);
  }

  public paginationChange() {
    this.getEquipmentData();
  }

  public showActivityLogDialog(): void {
    const overlayRef = this._overlay.create({
      width: '450px',
      height: '600px',
      backdropClass: 'bg-slate-400/50',
      panelClass: ['overflow-y-auto', 'rounded-lg'],
      hasBackdrop: true,
      positionStrategy: new GlobalPositionStrategy()
        .bottom('20px')
        .right('15px'),
      scrollStrategy: this._overlay.scrollStrategies.block()
    });
    const activityLogPortal = new ComponentPortal(
      BusinessCaseActivityLogComponent
    );
    const componentRef = overlayRef.attach(activityLogPortal);

    componentRef.setInput('businessCaseId', this.businessCase?.id as number);
    componentRef.instance.overlayRef = overlayRef;
  }

  public showSeenAsset(): void {
    this._seenAssetOverlay.open(AssetListSeenOverlayComponent, {
      width: '550px',
      height: '520px'
    });
  }

  public showUnseenAsset(): void {
    this._seenAssetOverlay.open(AssetListUnseenOverlayComponent, {
      width: '550px',
      height: '560px'
    });
  }

  displayedColumns: string[] =
    ['discipline', 'equipmentText', 'equipmentId', 'strategy', 'implementationDate', 'refreshDate', 'reject'
    ];

}

