<form [formGroup]="businessCaseForm" class="flex flex-col w-full">
  <section class="flex flex-col w-full mb-4">
    <div class="rounded w-full bg-slate-100 p-4">
      <h4 class="font-semibold">Details</h4>
    </div>

    <div class="w-full flex flex-col mb-4 pt-8">
      <mat-form-field appearance="outline" floatLabel="always" class="w-full">
        <mat-label
          matTooltip="Info about the action"
          class="flex flex-row justify-center"
          >Select Business Case Action Type
          <mat-icon class="ml-2">info</mat-icon></mat-label
        >
        <mat-select formControlName="businessCaseActionType">
          @for (type of businessCaseActionTypes; track type) {
          <mat-option [value]="type">{{
            type.trim().split('_').join(' ')
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>

    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="w-full mt-8 mb-4"
    >
      <mat-label
        matTooltip="Info about the action"
        class="flex flex-row items-center"
        >Business case name
        <mat-icon class="ml-2">info</mat-icon>
      </mat-label>
      <input type="text" matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" class="w-full">
      <mat-label
        matTooltip="Info about the action"
        class="flex flex-row items-center"
        >Implementation Date
        <mat-icon class="ml-2">info</mat-icon>
      </mat-label>

      <input
        matInput
        [matDatepicker]="picker"
        formControlName="implementationDate"
      />

      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" class="w-full">
      <mat-label
        matTooltip="Info about the action"
        class="flex flex-row items-center"
        >Business case description
        <mat-icon class="ml-2">info</mat-icon>
      </mat-label>
      <textarea matInput rows="5" formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="w-full mb-4"
    >
      <mat-label
        matTooltip="Info about the action"
        class="flex flex-row items-center"
        >Town
        <mat-icon class="ml-2">info</mat-icon>
      </mat-label>
      <input type="text" matInput formControlName="town" />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="w-full mb-4"
    >
      <mat-label
        matTooltip="Info about the action"
        class="flex flex-row items-center"
        >Province
        <mat-icon class="ml-2">info</mat-icon>
      </mat-label>
      <input type="text" matInput formControlName="province" />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="w-full mb-4"
    >
      <mat-label
        matTooltip="Info about the action"
        class="flex flex-row items-center"
        >Building number
        <mat-icon class="ml-2">info</mat-icon>
      </mat-label>
      <input type="text" matInput formControlName="buildingNumber" />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="w-full mb-4"
    >
      <mat-label
        matTooltip="Info about the action"
        class="flex flex-row items-center"
        >Outlet Type
        <mat-icon class="ml-2">info</mat-icon>
      </mat-label>
      <input type="text" matInput formControlName="outletType" />
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" class="w-full">
      <mat-label
        matTooltip="Info about the action"
        class="flex flex-row items-center"
        >Outlet
        <mat-icon class="ml-2">info</mat-icon>
      </mat-label>
      <input type="text" matInput formControlName="outlet" />
    </mat-form-field>
  </section>

  <mat-divider></mat-divider>

  <section class="flex flex-col w-full my-4">
    <div class="rounded w-full bg-slate-100 p-4">
      <h4 class="font-semibold">Uploads</h4>
    </div>

    <div class="flex flex-row justify-start py-4">
      <div class="flex flex-col mr-4">
        <bidvest-file-upload
          (uploadSuccess)="requiredDocs.cdaLayout = true"
          [allowedMimeTypes]="['.pdf', '.png', '.docx', '.jpg', '.jpeg']"
          [businessCaseId]="businessCaseId"
          [url]="'documents/CONSTRUCTION_DRAWING'"
          [required]="true"
        ></bidvest-file-upload>
        <small class="font-semibold mt-4">CONSTRUCTION DRAWING</small>
      </div>
      <div class="flex flex-col mr-4">
        <bidvest-file-upload
          (uploadSuccess)="requiredDocs.iopSchedule = true"
          [allowedMimeTypes]="['.pdf', '.png', '.docx', '.jpg', '.jpeg']"
          [businessCaseId]="businessCaseId"
          [url]="'documents/IOP_SCHEDULE'"
          [required]="true"
        ></bidvest-file-upload>
        <small class="font-semibold mt-4">IOP SCHEDULE</small>
      </div>
      <div class="flex flex-col mr-4">
        <bidvest-file-upload
          (uploadSuccess)="requiredDocs.rr03PropertyList = true"
          [allowedMimeTypes]="['.pdf', '.png', '.docx', '.jpg', '.jpeg']"
          [businessCaseId]="businessCaseId"
          [url]="'documents/RR03_PROPERTY_LIST'"
          [required]="true"
        ></bidvest-file-upload>
        <small class="font-semibold mt-4">RR03 PROPERTY LIST</small>
      </div>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section class="flex flex-col w-full mt-4" formGroupName="collaborators">
    <div class="rounded w-full bg-slate-100 p-4">
      <h4 class="font-semibold">Collaborators</h4>
    </div>

    <div class="w-full flex flex-col mb-4">
      <span class="my-4">
        <h4 class="text-lg">Stages</h4>
      </span>
    </div>

    @for (stage of stages; track $index) {

    <bidvest-collaborators-selector
      [businessCaseAccounts]="collaborators"
      [collaboratorsData]="initialCollaboratorData"
      (collaboratorsSelection)="getCollaboratorSelection($event)"
    ></bidvest-collaborators-selector>

    <mat-divider></mat-divider>
    }
  </section>
</form>
