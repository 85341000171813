import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { BusinessCaseReviewStatus } from '../../enums/business-case-review-status.enum';
import { BusinessCaseReviewPayload } from '../../interfaces/business-case-review-payload.interface';
import { ReasonForRejectionOverlayComponent } from '@bidvest/features';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import {
  backOfficeRouteKeys,
  BusinessCaseHeaderComponent
} from '@bidvest/shared';
import { ComponentPortal } from '@angular/cdk/portal';
import { BusinessCaseActivityLogComponent } from '../../components/business-case-activity-log/business-case-activity-log.component';
import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { BusinessCaseService } from '../../services/business-case.service';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { IBusinessCaseOverview } from '../../interfaces/business-case-overview.interface';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BusinessCaseDetailsFormComponent } from '../../components/business-case-details-form/business-case-details-form.component';
import { BusinessCaseOverviewPanelComponent } from '../../components/business-case-overview-panel/business-case-overview-panel.component';
import { EngineeringServicesTabComponent } from '../../components/tables/engineering-services-overview-tab/engineering-services-overview-tab.component';
import { SoftServicesTabComponent } from '../../components/tables/soft-services-overview-tab/soft-services-overview-tab.component';
import { EngineeringServicesOverviewComponent } from '../engineering-services-overview/engineering-services-overview.component';

@Component({
  selector: 'bidvest-business-case-overview',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatDividerModule,
    BusinessCaseDetailsFormComponent,
    BusinessCaseOverviewPanelComponent,
    BusinessCaseActivityLogComponent,
    BusinessCaseHeaderComponent,
    SoftServicesTabComponent,
    EngineeringServicesOverviewComponent,
    RouterLink,
    MatTooltipModule
  ],
  templateUrl: './business-case-overview.component.html',
  styleUrl: './business-case-overview.component.scss'
})
export class BusinessCaseOverviewComponent implements OnInit, OnDestroy {
  public businessCaseId!: number;
  public businessCase: IBusinessCaseDetails | undefined;
  public businessCaseOverview!: IBusinessCaseOverview;
  public businessCaseStages: Array<BusinessCaseStage> = [];
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  @Input()
  public set stageConfigs(value: Array<IBusinessCaseStageConfig>) {
    this._resolvedData$.next(value);
  }
  private readonly _resolvedData$: BehaviorSubject<Array<IBusinessCaseStageConfig> | null> =
    new BehaviorSubject<Array<IBusinessCaseStageConfig> | null>(null);
  private _destroy$: Subject<void> = new Subject();
  constructor(
    private readonly _loader: NgxUiLoaderService,
    private readonly _toastr: ToastrService,
    private readonly _route: ActivatedRoute,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _overlay: Overlay,
    private readonly _dialog: MatDialog,
    private readonly _router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    this._loader.start();
    try {
      const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
      this.businessCaseId = routeParams.get('id') as unknown as number;

      this.businessCaseStagesConfigs = (await firstValueFrom(
        this._resolvedData$.asObservable()
      )) as unknown as Array<IBusinessCaseStageConfig>;

      this.businessCase = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
      );

      this.businessCaseStages = this.businessCase.collaborators.map(
        (stage) => ({
          id: stage.id,
          stageName: stage.businessCaseStageName
        })
      );

      this.businessCaseOverview = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseOverview(this.businessCaseId)
      );

      this._loader.stop();
    } catch (error) {
      this._toastr.error(
        `There was as issue loading the business case details with id ${this.businessCaseId}`
      );
      this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public isReadOnlyMode(): boolean {
    return !this.businessCaseStagesConfigs.find(
      (config) => config.stageName === this.businessCase?.activeStageName
    )?.canEdit as boolean;
  }

  public showActivityLogDialog(): void {
    const overlayRef = this._overlay.create({
      width: '450px',
      height: '600px',
      backdropClass: 'bg-slate-400/50',
      panelClass: ['overflow-y-auto', 'rounded-lg'],
      hasBackdrop: true,
      positionStrategy: new GlobalPositionStrategy()
        .bottom('20px')
        .right('15px'),
      scrollStrategy: this._overlay.scrollStrategies.block()
    });
    const activityLogPortal = new ComponentPortal(
      BusinessCaseActivityLogComponent
    );
    const componentRef = overlayRef.attach(activityLogPortal);

    componentRef.setInput('businessCaseId', this.businessCase?.id as number);
    componentRef.instance.overlayRef = overlayRef;
  }

  public rejectBusinessCaseCreation(): void {
    const dialogRef: MatDialogRef<ReasonForRejectionOverlayComponent> =
      this._dialog.open(ReasonForRejectionOverlayComponent, {
        width: '600px',
        height: '450px'
      });

    dialogRef
      .beforeClosed()
      .subscribe(async (data: { reason: string; details: string }) => {
        const payload: BusinessCaseReviewPayload = {
          businessCaseId: this.businessCase?.id as number,
          businessCaseStageId: this.businessCase?.activeStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
          rejectedReason: data.reason,
          moreDetails: data.details
        };

        try {
          this._loader.start();

          await firstValueFrom(
            this._businessCaseService.rejectBusinessCase(payload)
          );

          this._loader.stop();
          this._toastr.success(
            `Business case ${payload.businessCaseId} has been rejected successfully!`
          );

          this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
        } catch (error) {
          console.log(error);
          this._toastr.error(
            `There was as issue rejecting the business case with id ${payload.businessCaseId}`
          );
        }
      });
  }

  public async approveBusinessCaseCreation(): Promise<void> {
    this._loader.start();

    const payload: BusinessCaseReviewPayload = {
      businessCaseId: this.businessCase?.id as number,
      businessCaseStageId: this.businessCase?.activeStageId as number,
      businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
      rejectedReason: '',
      moreDetails: ''
    };

    try {
      this._loader.start();

      await firstValueFrom(
        this._businessCaseService.approveBusinessCase(payload)
      );

      this._loader.stop();
      this._toastr.success(
        `Business case ${payload.businessCaseId} has been approved successfully!`
      );

      this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
    } catch (error) {
      this._toastr.error(
        `There was as issue approving the business case with id ${payload.businessCaseId}`
      );
    }
  }

  public includesSoftServices(): boolean {
    return (
      this.businessCase?.businessCaseType! === 'DOWN_SELL_SOFT_SERVICES' ||
      this.businessCase?.businessCaseType! === 'UPSELL_SOFT_SERVICES' ||
      this.businessCase?.businessCaseType! === 'UPSELL_CATERING' ||
      this.businessCase?.businessCaseType! === 'TERMINATION_CATERING' ||
      this.businessCase?.businessCaseType! === 'DOWN_SELL_CATERING' ||
      this.businessCase?.businessCaseType! === 'TERMINATION_ATM_SINGLE_BC' ||
      this.businessCase?.businessCaseType! === 'TERMINATION_ATM_GROUP_BC' ||
      this.businessCase?.businessCaseType! ===
        'DOWN_SELL_SOFT_AND_ENGINEERING_SERVICES' ||
      this.businessCase?.businessCaseType! ===
        'NEW_BUILDING_SOFT_AND_ENGINEERING_SERVICES' ||
      this.businessCase?.businessCaseType! ===
        'TERMINATION_ENGINEERING_AND_SOFT_SERVICES' ||
      this.businessCase?.businessCaseType! ===
        'UPSELL_SOFT_AND_ENGINEERING_SERVICES'
    );
  }

  public includesEngineeringServices(): boolean {
    return (
      this.businessCase?.businessCaseType! ===
        'DOWN_SELL_ENGINEERING_SERVICES' ||
      this.businessCase?.businessCaseType! === 'UPSELL_ENGINEERING_SERVICES' ||
      this.businessCase?.businessCaseType! ===
        'DOWN_SELL_SOFT_AND_ENGINEERING_SERVICES' ||
      this.businessCase?.businessCaseType! ===
        'NEW_BUILDING_SOFT_AND_ENGINEERING_SERVICES' ||
      this.businessCase?.businessCaseType! ===
        'TERMINATION_ENGINEERING_AND_SOFT_SERVICES' ||
      this.businessCase?.businessCaseType! ===
        'UPSELL_SOFT_AND_ENGINEERING_SERVICES'
    );
  }
}
