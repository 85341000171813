import { CommonModule } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { CustomToastrService } from "../../../services/custom-toastr.service";

@Component({
  selector: 'bidvest-error-toast-component',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ],
  templateUrl: './error-toastr.component.html',
  providers: [],
  styleUrl: './error-toastr.component.scss'
})
export class ErrorToastComponent {
  public constructor(
    @Inject(MAT_SNACK_BAR_DATA) public  data: {
      title: string,
      description: string,
      type: 'warning' | 'info'},
    private toastrService: CustomToastrService
  ) {}

  public closeOnClick() {
    this.toastrService.closeToastr();
  }

}
