import {
  Component,
  EnvironmentInjector,
  inject,
  Input,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { BusinessCaseDraftComponent } from '@bidvest/features';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { BusinessCaseOverviewComponent } from '../../views/business-case-overview/business-case-overview.component';

@Component({
  selector: 'bidvest-business-case-view-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './business-case-view-container.component.html',
  styleUrl: './business-case-view-container.component.scss'
})
export class BusinessCaseViewContainerComponent implements OnInit {
  public businessCaseId!: number;
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  public inputs!: { stageConfigs: Array<IBusinessCaseStageConfig> };

  @Input()
  public set config(value: Array<BusinessCaseStage>) {
    this._resolvedData$.next(value);
  }

  private readonly _resolvedData$: BehaviorSubject<Array<BusinessCaseStage> | null> =
    new BehaviorSubject<Array<BusinessCaseStage> | null>(null);

  private readonly _loader: NgxUiLoaderService = inject(NgxUiLoaderService);
  private readonly _route: ActivatedRoute = inject(ActivatedRoute);
  private readonly _ref = inject(ViewContainerRef);
  private readonly _envInjector = inject(EnvironmentInjector);

  public async ngOnInit(): Promise<void> {
    this._loader.start();

    try {
      const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
      this.businessCaseId = routeParams.get('id') as unknown as number;

      this.businessCaseStagesConfigs = (await firstValueFrom(
        this._resolvedData$.asObservable()
      )) as unknown as Array<IBusinessCaseStageConfig>;

      const activeStage: IBusinessCaseStageConfig =
        this.businessCaseStagesConfigs?.find(
          (stage) => stage.stageActive === true
        ) as IBusinessCaseStageConfig;

      if (activeStage.canEdit && activeStage.stageName === 'SETUP') {
        const comp = this._ref.createComponent(BusinessCaseDraftComponent, {
          environmentInjector: this._envInjector
        });
        comp.setInput('stageConfigs', this.businessCaseStagesConfigs);
      } else {
        const comp = this._ref.createComponent(BusinessCaseOverviewComponent, {
          environmentInjector: this._envInjector
        });
        comp.setInput('stageConfigs', this.businessCaseStagesConfigs);
      }

      this._loader.stop();
    } catch (error) {}
  }
}
