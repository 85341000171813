import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { IRateCardDataType } from '../../../models/rate-card-data';
import { IBusinessCaseSoftServiceCostOverview } from '../../../models/business-case-service-cost-overview.interface';
import { IBusinessCaseOverviewSoftServiceItem } from '../../../models/business-case-overview-soft-service-item';
import { BusinessCaseService } from '../../../services/business-case.service';

@Component({
  selector: 'bidvest-soft-services-tab',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './soft-services-overview-tab.component.html',
  styleUrl: './soft-services-overview-tab.component.scss'
})
export class SoftServicesTabComponent implements OnInit {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  @Input() public businessCaseId: number | undefined;
  public softServiceData: IBusinessCaseSoftServiceCostOverview | undefined;
  public displayedColumns: string[] = ['serviceName', 'unitCost', 'quantity'];
  // @ts-ignore
  public dataSource;

  constructor(private readonly _businessCaseService: BusinessCaseService) {
  }
  public async ngOnInit(): Promise<void> {
    await this.loadBusinessCaseSoftServices();
  }

  private async loadBusinessCaseSoftServices(): Promise<void> {
    // @ts-ignore
    this.softServiceData = await this._businessCaseService.fetchBusinessCaseSoftService(this.businessCaseId)
    this.dataSource = new MatTableDataSource(this.softServiceData?.serviceItemModels);
  }
}
