<table mat-table [dataSource]="dataSource" appearance="outline">

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let element; let i = index" class="category-text"> {{getHardcodedValue(i)}} </td>
  </ng-container>

  <ng-container matColumnDef="gmpBefore">
    <th mat-header-cell *matHeaderCellDef>GMP Before change</th>
    <td mat-cell class="beforeCell" *matCellDef="let element">R{{ element.gmpBefore }}.00</td>
  </ng-container>

  <ng-container matColumnDef="removedAssets">
    <th mat-header-cell *matHeaderCellDef>Removed asset not replaced</th>
    <td mat-cell class="removedCell" *matCellDef="let element">R-0{{ element.removedAsset }}.00</td>
  </ng-container>

  <ng-container matColumnDef="revisedGmpOne">
    <th mat-header-cell *matHeaderCellDef>Revised GMP before first time new assets</th>
    <td mat-cell *matCellDef="let element">R{{ element.revisedGmp }}.00</td>
  </ng-container>

  <ng-container matColumnDef="reactiveOne">
    <th mat-header-cell *matHeaderCellDef>% Reactive</th>
    <td mat-cell *matCellDef="let element">%{{ element.revisedGmp }}00</td>
  </ng-container>

  <ng-container matColumnDef="firstTimeInstallation">
    <th mat-header-cell *matHeaderCellDef>First time installation</th>
    <td mat-cell *matCellDef="let element">R{{ element.revisedGmp }}.00</td>
  </ng-container>

  <ng-container matColumnDef="reactiveTwo">
    <th mat-header-cell *matHeaderCellDef>% Reactive</th>
    <td mat-cell *matCellDef="let element">%{{ element.revisedGmp }}00</td>
  </ng-container>

  <ng-container matColumnDef="revisedGmpTwo">
    <th mat-header-cell *matHeaderCellDef>Revised GMP</th>
    <td mat-cell *matCellDef="let element">R{{ element.revisedGmp }}.00</td>
  </ng-container>

  <ng-container matColumnDef="removalOfReactiveCharge">
    <th mat-header-cell *matHeaderCellDef>Removal of reactive charge of asset being replaced (Proportionate)</th>
    <td mat-cell *matCellDef="let element">R{{ element.revisedGmp }}.00</td>
  </ng-container>

  <ng-container matColumnDef="additionOfReactiveCharge">
    <th mat-header-cell *matHeaderCellDef>Addition of reactive charge of asset being replaced (Proportionate)</th>
    <td mat-cell *matCellDef="let element">R{{ element.revisedGmp }}.00</td>
  </ng-container>

  <ng-container matColumnDef="finalRevisedGmp">
    <th mat-header-cell *matHeaderCellDef>Final Revised GMP</th>
    <td mat-cell class="beforeCell" *matCellDef="let element">R{{ element.revisedGmp }}.00</td>
  </ng-container>

  <ng-container matColumnDef="gmpNet">
    <th mat-header-cell *matHeaderCellDef>GMP NET Impact</th>
    <td mat-cell class="removedCell" *matCellDef="let element">R{{ element.revisedGmp }}.00</td>
  </ng-container>

  <mat-paginator aria-label="Select page"> </mat-paginator>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>