import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, firstValueFrom, Observable, Subject, throwError } from 'rxjs';
import { IRateCardDataType } from '../models/rate-card-data';
import { RateCard } from '../models/rate-card.interface';
import { IPaginationData } from '../models/pagination-data';
import { IRateCardFilter } from '../models/rate-card-filter';
import { IEngineeringTableData } from '../models/enigneering-table-data';

@Injectable({
  providedIn: 'root'
})
export class RateCardsService {
  private baseUrl: string = environment.apiUrl;
  private httpClient: HttpClient = inject(HttpClient);
  private mockRateCardDataUrl = 'assets/dummy-data/rate-cards.json';
  private mockSapRateCardUrl = 'assets/dummy-data/confirm-rate-link-mock.json';
  private refreshRateCardTable = new Subject<void>;
  public refreshTable$ = this.refreshRateCardTable.asObservable();

  public triggerRateCardTableRefresh() {
    this.refreshRateCardTable.next();
  }

  public fetchRateCards(payload: IPaginationData): Promise<IRateCardFilter> {
    return firstValueFrom(this.httpClient.put<IRateCardFilter>(`${this.baseUrl}/rate-cards/filter`, payload));
  }

  public async getSingleRateCard(id: number): Promise<RateCard> {
    return firstValueFrom(
      this.httpClient.get<RateCard>(`${this.baseUrl}/rate-cards/${id}`)
    );
  }

  public createRateCard(newRateCard: IRateCardDataType): Observable<null> {
    return this.httpClient
      .post<null>(`${this.baseUrl}/rate-cards`, newRateCard)
      .pipe(
        catchError((error: Error) => {
          return throwError(() => error);
        })
      );
  }

  public updateRateCard(updatedDetails: IRateCardDataType): Promise<IRateCardDataType> {
    return firstValueFrom(this.httpClient
      .put<any>(`${this.baseUrl}/rate-cards`, updatedDetails)
      .pipe(
        catchError((error: Error) => {
          return throwError(() => error);
        })
      ));
  }

  public async getSapRateCards(): Promise<IEngineeringTableData[]> {
    return firstValueFrom(
      this.httpClient.get<IEngineeringTableData[]>(`${this.mockSapRateCardUrl}`)
    );
  }

}
