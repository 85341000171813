import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { PermissionsSelectorComponent } from '../../permissions-selector/permissions-selector.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfileSettingsOverlayComponent } from '../../profile-account-settings/profile-account-settings.component';
import { ProfileNotificationsOverlayComponent } from '../../profile-notifications-settings/profile-notifications-settings.component';

@Component({
  selector: 'bidvest-profile-settings-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatDividerModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatTabsModule,
    ProfileSettingsOverlayComponent,
    ProfileNotificationsOverlayComponent,
    MatIconModule,
    MatDialogClose
  ],
  templateUrl: './profile-settings-overlay.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './profile-settings-overlay.component.scss'
})
export class AccountSettingsFormComponent implements OnInit {
  public form!: FormGroup;
  private readonly _dialogRef: MatDialogRef<AccountSettingsFormComponent> =
  inject(MatDialogRef);
  public constructor(
    private readonly _fb: FormBuilder,
  ) { }

  public ngOnInit(): void {

  }

  public closeSettingsDialog() {
    this._dialogRef.close();
  }

}
