import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IBusinessCaseActivityLogMessage } from '../../models/business-case-activity-log-message';
import { IActivityLog } from '../../interfaces/activity-log.interface';

@Component({
  selector: 'bidvest-business-case-activity-log-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './business-case-activity-log-message.component.html',
  styleUrl: './business-case-activity-log-message.component.scss'
})
export class BusinessCaseActivityLogMessageComponent {
  @Input()
  public data!: IActivityLog;
}
