<div class="w-full overflow-x-auto">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="caseNumber">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox
        >Reference Number
      </th>
      <td mat-cell *matCellDef="let businessCase">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(businessCase) : null"
          [checked]="selection.isSelected(businessCase)"
        >
        </mat-checkbox>
        {{ businessCase.referenceNumber }}
      </td>
    </ng-container>

    <ng-container matColumnDef="businessCaseName">
      <th mat-header-cell *matHeaderCellDef>Business case name</th>
      <td
        mat-cell
        *matCellDef="let businessCase"
        [matTooltip]="businessCase.businessCaseName"
      >
        @if (businessCase.businessCaseName !== null &&
        businessCase.businessCaseName !== undefined) {
        {{ businessCase.businessCaseName }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="implementationDate">
      <th mat-header-cell *matHeaderCellDef>Implementation Date</th>
      <td mat-cell *matCellDef="let businessCase">
        @if (businessCase.implementationDate !== null &&
        businessCase.implementationDate !== undefined) {
        {{ businessCase.implementationDate | date }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="initiationDate">
      <th mat-header-cell *matHeaderCellDef>Initiation Date</th>
      <td mat-cell *matCellDef="let businessCase">
        @if (businessCase.initiationDate !== null && businessCase.initiationDate
        !== undefined) {
        {{ businessCase.initiationDate | date }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="buildingNumber">
      <th mat-header-cell *matHeaderCellDef>Building Number</th>
      <td mat-cell *matCellDef="let businessCase">
        @if (businessCase.buildingNumber !== null && businessCase.buildingNumber
        !== undefined) {
        {{ businessCase.buildingNumber }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="handler">
      <th mat-header-cell *matHeaderCellDef>Handler</th>
      <td mat-cell *matCellDef="let businessCase">
        @if (businessCase.activeStageOwner !== null &&
        businessCase.activeStageOwner !== undefined) {
        {{ businessCase.activeStageOwner }}
        } @else { <span class="text-xs">N/A</span> }
      </td>
    </ng-container>

    <ng-container matColumnDef="stage">
      <th mat-header-cell *matHeaderCellDef>Stage</th>
      <td mat-cell *matCellDef="let businessCase">
        <div
          class="py-1 px-4 bg-gray-500 rounded text-white font-bold flex flex-row justify-center items-center text-xs text-nowrap"
        >
          {{ businessCase.activeStage }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let businessCase">
        <div
          class="rounded-full py-1 px-4 flex flex-row justify-center items-center text-white font-bold bg-blue-800 text-xs"
        >
          {{ businessCase.activeStageStatus }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let businessCase">
        <button
          mat-icon-button
          (click)="
            viewBusinessCase(businessCase.id, businessCase.activeStageStatus)
          "
        >
          <mat-icon>visibility</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="
            deleteBusinessCase(businessCase.id, businessCase.referenceNumber)
          "
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="table-row"
    ></tr>

    <tr class="w-full" *matNoDataRow>
      <td [colSpan]="displayedColumns.length">
        <div class="h-96 flex flex-row justify-center items-center font-bold">
          No Data
        </div>
      </td>
    </tr>
  </table>
</div>

<mat-paginator
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
  aria-label="Select page of business cases"
>
</mat-paginator>
