import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CreateUserPayload } from '../interfaces/create-user-payload.interface';
import { environment } from '@env/environment';
import { GetUsersPayload } from '../interfaces/get-users-payload.interface';
import { GetUsersResponse } from '../interfaces/get-users-response.interface';
import { Observable } from 'rxjs';
import { User } from '../interfaces/user.interface';
import { UpdateUserPayload } from '../interfaces/update-user-payload.interface';
import { IUserRoles } from '../models/user-roles';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseUrl: string = environment.apiUrl;
  public userRoles: Array<IUserRoles> = [
    { value: 'ADMIN', role: 'Admin' },
    { value: 'GENERAL_MANAGER', role: 'General Manager' },
    {
      value: 'ENGINEERING_SERVICE_MANAGER',
      role: 'Engineering Service Manager'
    },
    { value: 'PRINCIPLE_ENGINEER', role: 'Principal Engineer' },
    { value: 'SITE_PROJECT_MANAGER', role: 'Site Project Manager' },
    { value: 'PROCUREMENT', role: 'Procurement' },
    { value: 'GOVERNANCE', role: 'Governance' },
    { value: 'LEGAL', role: 'Legal' },
    {
      value: 'BIDVEST_FINANCIAL_MANAGEMENT',
      role: 'Bidvest Financial Management'
    },
    {
      value: 'ABSA_FINANCIAL_MANAGEMENT_VP',
      role: 'ABSA Financial Management VP'
    },
    { value: 'ABSA_ENGINEERING_VP', role: 'ABSA Engineering VP' },
    { value: 'CHIEF_COMMERCIAL_OFFICER', role: 'Chief Commercial Officer' }
  ];

  private _http: HttpClient = inject(HttpClient);

  public createNewUser(payload: CreateUserPayload) {
    return this._http.post(`${this.baseUrl}/accounts`, payload);
  }

  public getAllUsers(filterParams: GetUsersPayload): Observable<GetUsersResponse> {
    return this._http.put<GetUsersResponse>(
      `${this.baseUrl}/accounts/filter`,
      filterParams
    );
  }

  public getUserById(id: number): Observable<User> {
    return this._http.get<User>(`${this.baseUrl}/accounts/${id}`);
  }

  public activateUser(id: number): Observable<void> {
    return this._http.put<void>(`${this.baseUrl}/accounts/${id}/activate`, {});
  }

  public deactivateUser(id: number): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/accounts/${id}/deactivate`,
      {}
    );
  }

  public updateUser(payload: UpdateUserPayload): Observable<void> {
    return this._http.put<void>(`${this.baseUrl}/accounts`, payload);
  }
}
