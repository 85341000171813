<div class="mb-4">
  <h4 class="text-primary-blue font-bold text-2xl">Business Cases</h4>
</div>
<bidvest-business-cases-metrics-panel
  [data]="overviewTotals"
  (dateRangeSelect)="updateTableDateRange($event)"
></bidvest-business-cases-metrics-panel>
<div class="flex justify-between items-center pt-8 pb-4">
  <div class="flex flex-row justify-start">
    <mat-form-field class="mr-6" appearance="outline">
      <mat-label>Search</mat-label>
      <input
        style="font-size: 12px"
        type="text"
        matInput
        placeholder="Search"
        name="searchTerm"
        [(ngModel)]="searchTerm"
      />
      <mat-icon matSuffix (click)="search()">search</mat-icon>
    </mat-form-field>
    <mat-form-field class="mr-6" appearance="outline">
      <mat-label>Filter</mat-label>
      <mat-select [(ngModel)]="businessCaseStage">
        <mat-option [value]="" (click)="applyFilter('')">ALL</mat-option>
        @for (category of filterCategoriesKeys; track category) {
        <mat-option [value]="category" (click)="applyFilter(category)">{{
          category.split('_').join(' ')
        }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <span class="flex flex-col justify-start">
      <small class="text-sm font-semibold">Active Status Filter:</small>
      <mat-basic-chip
        ><mat-chip class="flex flex-row justify-center">{{
          activeStatusFilter.length === 0 ? 'None' : activeStatusFilter
        }}</mat-chip></mat-basic-chip
      >
    </span>
  </div>

  <div class="flex-row flex items-center justify-center">
    <button
      mat-stroked-button
      color="accent"
      class="mr-4"
      (click)="resetTableFilters()"
    >
      Reset filters
    </button>
    <button
      mat-flat-button
      color="accent"
      (click)="showNewBusinessCaseDialog()"
    >
      <mat-icon>add</mat-icon>
      New business case
    </button>
  </div>
</div>
<bidvest-business-cases-table
  [data]="data"
  (actions)="handleBusinessCaseAction($event)"
></bidvest-business-cases-table>
