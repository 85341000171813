<div class="w-full flex flex-row justify-between items-center p-4">
  <h4 class="font-semibold text-primary-blue inline-block">
    Reason for rejection
  </h4>

  <button mat-icon-button matDialogClose>
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div matDialogContent>
  <form
    [formGroup]="rejectionReasonForm"
    (ngSubmit)="submitForm()"
    class="h-full py-8"
  >
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Select reason</mat-label>
      <mat-select formControlName="reason" name="reason">
        @for (reason of rejectionReasons; track reason) {
        <mat-option [value]="reason">{{ reason }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-divider></mat-divider>
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>More details</mat-label>
      <textarea type="text" matInput formControlName="details"></textarea>
    </mat-form-field>

    <div class="py-2">
      <mat-divider></mat-divider>
    </div>

    <div class="flex flex-row w-full py-4">
      <button
        class="w-full"
        mat-flat-button
        type="submit"
        [disabled]="!rejectionReasonForm.valid"
        color="accent"
      >
        Submit
      </button>
    </div>
  </form>
</div>
