<div class="flex flex-col bg-white overflow-y-auto p-2">
  <div
    class="rounded w-full bg-slate-100 p-4 flex flex-row justify-start items-center"
  >
    <h4 class="font-semibold flex-grow m-0">Activity Log</h4>

    @if (this.overlayRef !== undefined && this.overlayRef !== null) {
    <button mat-icon-button (click)="closeOverlay()">
      <mat-icon>clear</mat-icon>
    </button>
    }
  </div>

  <div class="py-4 border-b-[1px] w-full relative">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Add comment</mat-label>
      <textarea
        #textArea
        matInput
        [(ngModel)]="newCommentInput"
        rows="8"
        class="res"
        (focus)="showSubmitBtn = false"
        (blur)="showSubmitBtn = true"
      ></textarea>
    </mat-form-field>
    @if (showSubmitBtn) {
    <span class="absolute bottom-10 right-1">
      <button mat-icon-button (click)="submitComment()">
        <mat-icon>send</mat-icon>
      </button>
    </span>
    }
  </div>

  @for (message of data | orderBy: 'createdDateTime' : 'desc'; track message.id)
  {
  <bidvest-business-case-activity-log-message
    [data]="message"
  ></bidvest-business-case-activity-log-message>
  }
</div>
