import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { IRateCardDataType } from '../../../models/rate-card-data';
import { IViewSoftServices } from '../../../models/view-soft-services';
import { ISoftServicesBusinessCaseDetails } from '../../../models/soft-service-business-case-details';
import { IBusinessCaseDetails } from '../../../interfaces/business-case-details.interface';
import { SoftServiceCategories } from '../../../enums/soft-service-categories.enum';
import { backOfficeRouteKeys } from '@bidvest/shared';
import { SoftServicesService } from '../../../services/soft-services.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BusinessCaseService } from '../../../services/business-case.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { firstValueFrom } from 'rxjs';
import { IAddSoftServicesData } from '../../../models/add-soft-services-data';

export interface ISoftServciesTabData {
  serviceName: string;
  unitCost: number;
  quantity: number;
}

const ELEMENT_DATA: ISoftServciesTabData[] = [
  { serviceName: 'Airconditioning', unitCost: 0.0, quantity: 0 }
];

@Component({
  selector: 'bidvest-soft-services-read-only-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './soft-services-read-only-table.component.html',
  styleUrl: './soft-services-read-only-table.component.scss'
})
export class SoftServicesReadOnlyTableComponent implements OnInit {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  public dataSource = new MatTableDataSource<IRateCardDataType>([]);
  // @ts-ignore
  public rateCardData: IViewSoftServices;
  public softServicesData: Array<ISoftServicesBusinessCaseDetails> = [];
  public businessCaseId!: number;
  public businessCaseDetails: IBusinessCaseDetails | undefined;
  public size: number = 100;
  public resultsLength: number = 0;
  public displayedColumns: Array<string> = [
    'code',
    'serviceName',
    'category',
    'subCategory',
    'unitCost',
    'uom',
    'quantity'
  ];
  public readyForReview = false;
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  constructor(
    private readonly _softService: SoftServicesService,
    private readonly _route: ActivatedRoute,
    private readonly _toaster: ToastrService,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _router: Router,
    private readonly _loader: NgxUiLoaderService
  ) {}

  public async ngOnInit(): Promise<void> {
    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    this.businessCaseId = routeParams.get('id') as unknown as number;
    // this.businessCaseDetails = await firstValueFrom(
    //   this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
    // );
    await this.getAvailableServices();
    await this.getAddedSoftServices();
  }

  public async getAvailableServices(): Promise<void> {
    this.rateCardData = await this._softService.viewAvailableRateCardServices({
      currentPage: 1,
      category: '',
      size: 100000,
      searchTerm: ''
    });

    this.dataSource = new MatTableDataSource(this.rateCardData.rateCards);
    this.resultsLength = this.rateCardData.total;
  }

  public async getAddedSoftServices(): Promise<void> {
    this.softServicesData =
      await this._softService.viewBusinessCaseSoftServices(this.businessCaseId);
    this.readyForReview = this.softServicesData.length > 0;
    const inputElements = document.querySelectorAll('input[data-id]') as NodeListOf<HTMLInputElement>;
    // loop through and update the value
    this.softServicesData.forEach(value => {
      inputElements.forEach((input) => {
        if(Number(input.getAttribute('data-id')) === value.rateCardId) {
          input.value = String(value.quantity)
        }
      });
    })
  }

  public async submitReview(): Promise<void> {
    try{
      this._loader.start();

      await firstValueFrom(
        this._businessCaseService.submitBusinessCaseForReview({
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCaseDetails
            ?.activeStageId as number
        })
      );

      this._loader.stop();

      this._toaster.success(
        `Business Case ${this.businessCaseDetails?.referenceNumber} has been submitted for review`
      );

      await this._router.navigateByUrl(this.routes['BUSINESS_CASE'] + '/' + this.businessCaseId);
    } catch (error) {
      console.log(error);
      this._toaster.error(
        `There was an error submitting business case ${this.businessCaseDetails?.referenceNumber} for review.`
      );
    }
  }
}
