import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IBusinessCaseTableItem } from '../../models/business-case-table-item';
import { ServiceQuotesTableComponent } from '../../components/tables/service-quote-table/service-quote-table.component';

@Component({
  selector: 'bidvest-business-cases',
  standalone: true,
  imports: [CommonModule, ServiceQuotesTableComponent],
  templateUrl: './engineering-services-quotes.component.html',
  styleUrl: './engineering-services-quotes.component.scss'
})
export class EngineeringServicesQuotesComponent {}
