<div class="flex flex-row justify-between">
  <div class="bg-transparent text-white flex flex-col">
    <div class="flex flex-row">
      <img height="=20" width="20" src="assets/Infosvg.svg" />
      <div class="p-2">
        <p class="mr-2 text-sm text-semibold">Successfully added</p>
      </div>
    </div>
    <div>
      <p class="text-sm text-semibold">
        {{ description }}
      </p>
    </div>
  </div>
  <div>
    <mat-icon class="cursor-pointer" (click)="closeOnClick()">close</mat-icon>
  </div>
</div>