<div class="flex flex-row justify-between items-center pb-10">
  <div>
    <mat-form-field class="mr-2" appearance="outline">
      <mat-label>Search</mat-label>
      <input style="font-size: 12px" type="text" matInput placeholder="Search" name="searchTerm"
        [(ngModel)]="searchValue" />
      <mat-icon matSuffix (click)="triggerChange()">search</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Filter</mat-label>
      <mat-select [(ngModel)]="role">
        <mat-option [value]="" (click)="applyFilter('')">ALL</mat-option>
        @for (categories of filterCategoriesKeys; track categories) {
        <mat-option [value]="categories" (click)="applyFilter(categories)">{{categories}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mt-5">
    <button mat-flat-button color="accent" (click)="showNewRateCardDialog()">
      <mat-icon>add </mat-icon>
      New Rate Card
    </button>
  </div>
  <!-- <div>
    <button mat-flat-button color="accent" (click)="showToast()"> for demonstration purposes
      <mat-icon>add </mat-icon>
      Test button
    </button>
  </div> -->
</div>

<table mat-table [dataSource]="dataSource" appearance="outline">
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRows() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      ></mat-checkbox>
      <span class="font-semibold">Code</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(code) : null"
        [checked]="selection.isSelected(code)"
      ></mat-checkbox>
      {{ element.code}}
    </td>
  </ng-container>

  <ng-container matColumnDef="serviceName">
    <th mat-header-cell *matHeaderCellDef>
      <span class="font-semibold">Service name</span>
    </th>
    <td mat-cell *matCellDef="let element">{{ element.serviceName }}</td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>
      <span class="font-semibold">Category</span>
    </th>
    <td mat-cell *matCellDef="let element">{{ element.category }}</td>
  </ng-container>

  <ng-container matColumnDef="subCategory">
    <th mat-header-cell *matHeaderCellDef><span class="font-semibold">Sub category</span>
    </th>
    <td mat-cell *matCellDef="let element">{{ element.subCategory }}</td>
  </ng-container>

  <ng-container matColumnDef="unitCost">
    <th mat-header-cell *matHeaderCellDef>
      <span class="font-semibold">Unit cost</span>
    </th>
    <td mat-cell *matCellDef="let element">R {{ element.unitCost }}</td>
  </ng-container>

  <ng-container matColumnDef="uom">
    <th mat-header-cell *matHeaderCellDef>
      <span class="font-semibold">UOM</span>
    </th>
    <td mat-cell *matCellDef="let element">{{ element.unitOfMeasure }}</td>
  </ng-container>

  <ng-container matColumnDef="validThrough">
    <th mat-header-cell *matHeaderCellDef>
      <span class="font-semibold">Valid through</span>
    </th>
    <td mat-cell *matCellDef="let element">{{ element.validTillDate | date:'dd/MM/yyyy' }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>
      <span class="font-semibold">Status</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div
        class="{{statusChipChange( getStatus(element.validTillDate))  }}">
        {{ getStatus(element.validTillDate) }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th *matHeaderCellDef="edit"></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="selectedRateCardId(element.id)">
        <mat-icon>edit</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons aria-label="Select page of users">
</mat-paginator>