import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterLink, RouterModule } from '@angular/router';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog';
import { AccountSettingsFormComponent } from '../overlays/profile-settings-overlay/profile-settings-overlay.component';
import { backOfficeRouteKeys } from '@bidvest/shared';

@Component({
  selector: 'bidvest-profile-account-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatDialogTitle,
    MatDivider,
    MatDialogContent,
    MatDialogActions,
    RouterLink,
    AccountSettingsFormComponent,
    MatDialogClose
  ],
  templateUrl: './profile-account-settings.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './profile-account-settings.component.scss'
})
export class ProfileSettingsOverlayComponent implements OnInit {
  public form!: FormGroup;
  public routes: { [key: string]: string } = backOfficeRouteKeys;

  
  public constructor(
    private readonly _fb: FormBuilder,
  ) {
    
  }

  public async ngOnInit(): Promise<void> {
    this.initializeForm();
  }

  public initializeForm() {
    this.form = this._fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })
  }

}
