<div class="flex flex-row justify-between w-full items-center">
  <div matDialogTitle>
    <h4 class="font-semibold text-primary-blue text-2xl m-0 px-2 pb-6 pt-2">
      Settings
    </h4>
  </div>
  <div class="p-8 cursor-pointer text-gray-400">
    <mat-icon (click)="closeSettingsDialog()">close</mat-icon>
  </div>
</div>
<div matDialogContent class="overflow-visible w-full">
  <mat-tab-group animationDuration="200ms">
    <mat-tab label="Account" class="text-xl font-bold">
      <bidvest-profile-account-form></bidvest-profile-account-form>
    </mat-tab>
    <mat-tab label="Notifications">
      <bidvest-profile-notifications-form></bidvest-profile-notifications-form>
    </mat-tab>
  </mat-tab-group>
</div>