import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Injectable,
  inject,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';

@Component({
  selector: 'bidvest-reason-for-rejection-overlay',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatDialogContent,
    MatDialogClose
  ],
  templateUrl: './reason-for-rejection-overlay.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './reason-for-rejection-overlay.component.scss'
})
export class ReasonForRejectionOverlayComponent implements OnInit {
  public rejectionReasonForm!: FormGroup;
  public rejectionReasons: Array<string> = [
    'Incomplete Information',
    'Budgetary Concerns',
    'Insufficient Supporting Data',
    'Inaccurate Data',
    'Timing Conflicts',
    'Stakeholder Issues'
  ];
  public noReason: boolean = true;

  private readonly _fb: FormBuilder = inject(FormBuilder);
  private readonly _dialogRef: MatDialogRef<ReasonForRejectionOverlayComponent> =
    inject(MatDialogRef);

  public ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.rejectionReasonForm = this._fb.group({
      reason: ['', [Validators.required]],
      details: ['']
    });
  }

  public submitForm() {
    if (this.rejectionReasonForm.valid) {
      const { reason, details } = this.rejectionReasonForm.getRawValue();
      this._dialogRef.close({ reason, details });
    }
  }
}
