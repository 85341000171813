import { Component, OnInit } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ServiceQuotesTableComponent } from '../../components/tables/service-quote-table/service-quote-table.component';
import { BusinessCaseService } from '../../services/business-case.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { backOfficeRouteKeys, BusinessCaseHeaderComponent } from '@bidvest/shared';
import { IBusinessCaseStageConfig } from '../../interfaces/business-case-stage-config.interface';
import { IBusinessCaseSoftServiceQuote } from '../../models/business-case-soft-service-quote';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { IBusinessCaseDetails } from '../../interfaces/business-case-details.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReasonForRejectionOverlayComponent } from '@bidvest/features';
import { BusinessCaseReviewPayload } from '../../interfaces/business-case-review-payload.interface';
import { BusinessCaseReviewStatus } from '../../enums/business-case-review-status.enum';
import { ToastrService } from 'ngx-toastr';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'bidvest-soft-services-table-layout',
  standalone: true,
  imports: [
    CommonModule,
    ServiceQuotesTableComponent,
    BusinessCaseHeaderComponent,
    MatButton,
    MatIcon,
    MatIconButton,
    BusinessCaseHeaderComponent,
    MatIcon,
    TitleCasePipe,
    RouterLink,
    MatTooltip
  ],
  templateUrl: './soft-service-quotes-layout.component.html',
  styleUrl: './soft-service-quotes-layout.component.scss'
})
export class SoftServicesQuoteLayoutComponent implements OnInit {
  public routes: { [key: string]: string } = backOfficeRouteKeys;
  public businessCaseStagesConfigs: Array<IBusinessCaseStageConfig> = [];
  public businessCaseId!: number;
  public softServiceQuote: IBusinessCaseSoftServiceQuote | undefined;
  public businessCase: IBusinessCaseDetails | null = null;
  constructor(
    private readonly _loader: NgxUiLoaderService,
    private readonly _route: ActivatedRoute,
    private readonly _businessCaseService: BusinessCaseService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _toaster: ToastrService
  ) {}
  public async ngOnInit(): Promise<void> {
    await this._loader.start();
    const routeParams: ParamMap = await firstValueFrom(this._route.paramMap);
    this.businessCaseId = routeParams.get('id') as unknown as number;
    // load the business case soft services for review
    try {
      this.businessCaseStagesConfigs = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseStagesConfigs(
          this.businessCaseId
        )
      );
      await this.loadBusinessCaseSoftServices();

      this.businessCase = await firstValueFrom(
        // @ts-ignore
        this._businessCaseService.fetchBusinessCaseById(this.businessCaseId)
      );
      this.businessCaseStagesConfigs = await firstValueFrom(
        this._businessCaseService.fetchBusinessCaseStagesConfigs(
          // @ts-ignore
          this.businessCaseId
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      this._loader.stop();
    }
  }

  public isReviewer(): boolean {
    return this.businessCaseStagesConfigs.find(
      (config) => config.stageName === this.businessCase?.activeStageName
    )?.canRead as boolean;
  }
  public isEditor(): boolean {
    return this.businessCaseStagesConfigs.find(
      (config) => config.stageName === this.businessCase?.activeStageName
    )?.canEdit as boolean;
  }
  public rejectBusinessCaseCreation(): void {
    const dialogRef: MatDialogRef<ReasonForRejectionOverlayComponent> =
      this._dialog.open(ReasonForRejectionOverlayComponent, {
        width: '600px',
        height: '450px'
      });

    dialogRef
      .beforeClosed()
      .subscribe(async (data: { reason: string; details: string }) => {
        const payload: BusinessCaseReviewPayload = {
          businessCaseId: this.businessCase?.id as number,
          businessCaseStageId: this.businessCase?.activeStageId as number,
          businessCaseStageReviewStatus: BusinessCaseReviewStatus.REJECTED,
          rejectedReason: data.reason,
          moreDetails: data.details
        };

        try {
          this._loader.start();

          await firstValueFrom(
            this._businessCaseService.rejectBusinessCase(payload)
          );

          this._loader.stop();
          this._toaster.success(
            `Business case ${payload.businessCaseId} has been rejected.`
          );

          await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
        } catch (error) {
          console.log(error);
          this._toaster.error(
            `There was as issue rejecting the business case with id ${payload.businessCaseId}`
          );
        }
      });
  }

  public async approveBusinessCaseCreation(): Promise<void> {
    this._loader.start();

    const payload: BusinessCaseReviewPayload = {
      businessCaseId: this.businessCase?.id as number,
      businessCaseStageId: this.businessCase?.activeStageId as number,
      businessCaseStageReviewStatus: BusinessCaseReviewStatus.APPROVED,
      rejectedReason: '',
      moreDetails: ''
    };

    try {
      this._loader.start();

      await firstValueFrom(
        this._businessCaseService.approveBusinessCase(payload)
      );

      this._loader.stop();
      this._toaster.success(
        `Business case ${payload.businessCaseId} has been approved successfully.`
      );

      await this._router.navigateByUrl(this.routes['BUSINESS_CASES']);
    } catch (error) {
      console.log(error);
      this._toaster.error(
        `There was as issue approving the business case with id ${payload.businessCaseId}`
      );
    }
  }
  public async review(): Promise<void> {
    try {
      this._loader.start();

      await firstValueFrom(
        this._businessCaseService.submitBusinessCaseForReview({
          businessCaseId: this.businessCaseId as number,
          businessCaseStageId: this.businessCase
            ?.activeStageId as number
        })
      );

      this._loader.stop();

      this._toaster.success(
        `Business Case ${this.businessCase?.referenceNumber} has been submitted for review`
      );

      await this._router.navigateByUrl(this.routes['BUSINESS_CASE'] + '/' + this.businessCaseId);
    } catch (error) {
      console.log(error);
      this._toaster.error(
        `There was an error submitting business case ${this.businessCase?.referenceNumber} for review.`
      );
    }
  }
  private async loadBusinessCaseSoftServices(): Promise<void> {
    // @ts-ignore
    this.softServiceQuote = await firstValueFrom(
      this._businessCaseService.fetchBusinessCaseSoftServiceQuote(
        this.businessCaseId
      )
    );
  }
}
