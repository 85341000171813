import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, inject, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { RateCardsService } from '../../services/rate-cards.service';
import { RateCard } from '../../models/rate-card.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { CreateRateCardOverlayComponent } from '../overlays/create-rate-card-overlay/create-rate-card-overlay.component';
import { IRateCardDataType } from '../../models/rate-card-data';
import { Category } from '../../enums/category.enum';
import { CustomToastrService } from '../../services/custom-toastr.service';
import { IToastConfig } from '../../models/toast-config';
import { SelectionModel } from '@angular/cdk/collections';
import { IRateCardFilter } from '../../models/rate-card-filter';


@Component({
  selector: 'bidvest-rate-card-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatPaginatorModule
  ],
  templateUrl: './rate-card-table.component.html',
  styleUrl: './rate-card-table.component.scss'
})
export class RateCardTableComponent implements AfterViewInit, OnInit {

  @ViewChild(MatPaginator) public paginator!: MatPaginator;


  public data!: IRateCardFilter;
  public datesArray: Array<RateCard> = [];
  public newDates: Array<string> = [];
  private _dialog: MatDialog = inject(MatDialog);

  public  dataSource = new MatTableDataSource<IRateCardDataType>([]);
  public readonly selection = new SelectionModel<IRateCardDataType>(true, []);


  public isAllSelected(): boolean {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.dataSource.data.length;
    return numSelected === numRows;
  }


  public toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  @Output()
  public rateCardId: EventEmitter<number> = new EventEmitter();
  public filterCategoriesKeys = Object.keys(Category);


  public validationData: string = '';
  public size: number = 10;
  public searchValue: string | null = null;
  public categoryValue: string = '';
  public resultsLength: number = 0;
  public role: string = '';
  public searchTerm: string = '';

  constructor(
    private readonly _rateCardService: RateCardsService,
    private readonly _toast: CustomToastrService
  ) {

  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.subscribeTable();
  }

  public displayedColumns: string[] =
    ['code', 'serviceName', 'category', 'subCategory', 'unitCost', 'uom', 'validThrough', 'status', 'edit'];

  public selectedRateCardId(id: number) {
    this.rateCardId.emit(id);
  }

  public ngOnInit() {
    this.subscribeTable();
    this.populateTable();
  }

public async populateTable() {
  this.data = await this._rateCardService.fetchRateCards({
    currentPage: 1,
    category: this.categoryValue,
    size: this.size,
    searchTerm: this.searchValue
  })
  this.dataSource = new MatTableDataSource(this.data.rateCards);
  this.dataSource.paginator = this.paginator
}

public subscribeTable() {
  this._rateCardService.refreshTable$.subscribe(() => {
    this.populateTable();
  })
}

  public showNewRateCardDialog(): void {
    const dialogRef = this._dialog.open(CreateRateCardOverlayComponent, {
      width: '570px',
      height: '710px'
    });
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.fetchAllRateCards();
      }
    })
  }

  public getStatus(date: string) {
    const currentDate = new Date();
    const validUntil = new Date(date);
    if (validUntil >= currentDate) {
      return 'Valid'
    } else return 'Invalid'
  }

  public statusChipChange(valid: string) {
    return valid === 'Valid' ?
      'status-chip-valid' :
      'status-chip-invalid'
  }

  public async fetchAllRateCards() {
    this.data =  await this._rateCardService.fetchRateCards({
      currentPage: this.paginator.pageIndex,
      category: this.categoryValue,
      size: this.size,
      searchTerm: this.searchValue
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = changes['data'].currentValue;
  }

  public triggerChange() {
    this.fetchAllRateCards();
  }

  public applyFilter(event: string) {
    this.categoryValue = event;
    this.fetchAllRateCards();
  }

//For demonstration/testing purposes
  public showToast() {

    this._toast.openAlertToast({
      title: 'Success',
      description: 'This toastr works',
      config: {
        panelClass: 'alert-toast-full',
        duration: 0
      }
    });
  }

}
