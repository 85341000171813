import {
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  InputSignal,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { BusinessCaseStage } from '../../interfaces/business-case-setup.interface';
import { Collaborator } from '../../interfaces/update-business-case-payload.interface';
import { MatIconModule } from '@angular/material/icon';
import { IBusinessCaseAccount } from '../../interfaces/collaborator.interface';
import { Subject, takeUntil } from 'rxjs';
import { IStageCollaborationInput } from '../../interfaces/collaborator-input.interface';
import { difference, intersection } from 'lodash';
import { FilterByPipe, NgArrayPipesModule } from 'ngx-pipes';

@Component({
  selector: 'bidvest-collaborators-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    NgArrayPipesModule
  ],
  templateUrl: './collaborators-selector.component.html',
  styleUrl: './collaborators-selector.component.scss',
  providers: [FilterByPipe]
})
export class CollaboratorsSelectorComponent implements OnInit {
  public collaboratorsData = input.required<Collaborator>();

  // @Input()
  // public businessCaseAccounts: Array<IBusinessCaseAccount> = [];

  @Output()
  public collaboratorsSelection: EventEmitter<IStageCollaborationInput> = new EventEmitter<IStageCollaborationInput>();

  public isReadOnly: InputSignal<boolean> = input(false);

  public businessCaseAccounts: InputSignal<Array<IBusinessCaseAccount>> = input<
    Array<IBusinessCaseAccount>
  >([]);

  public collaboratorsForm!: FormGroup;
  public accounts: Array<IBusinessCaseAccount> = [];
  public availableAccountsIds: Array<number> = [];
  public availableReviewers: Array<IBusinessCaseAccount> = [];
  public availableStageOwners: Array<IBusinessCaseAccount> = [];
  public availableStageOwnerIds: Array<number> = [];
  public availableReviewerIds: Array<number> = [];

  private readonly _fb: FormBuilder = inject(FormBuilder);
  private readonly _destroy$: Subject<void> = new Subject();

  constructor() {
    effect(() => {
      this.collaboratorsForm.patchValue({
        owner: this.collaboratorsData().ownerId,
        reviewers: this.collaboratorsData().reviewerIds
      });

      this.accounts = this.businessCaseAccounts();

      // Set accounts available for reviewer selection by filtering
      // out accounts selected as stage owners
      this.availableReviewerIds = this.accounts
        .filter((account) => account.id !== this.collaboratorsData().ownerId)
        .map((account) => account.id);

      // Set accounts available for stage owner selection by filtering
      // out accounts selected as reviewers
      this.collaboratorsData().reviewerIds.forEach((value) => {
        this.availableStageOwnerIds = this.accounts
          .filter((account) => account.id !== value)
          .map((account) => account.id);
      });

      if (this.isReadOnly()) {
        this.collaboratorsForm.disable();
      }
    });
  }

  ngOnInit(): void {
    this.collaboratorsForm = this._fb.group({
      owner: new FormControl(),
      reviewers: new FormControl()
    });

    this.availableReviewerIds = this.accounts.map((account) => account.id);
    this.availableStageOwnerIds = this.accounts.map((account) => account.id);

    this.collaboratorsForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (values: { owner: number; reviewers: Array<number> }) => {
          if (values.owner !== null && values.reviewers.length > 0) {
            this.collaboratorsSelection.emit({
              id: this.collaboratorsData().id,
              ownerId: values.owner,
              reviewerIds: [...values.reviewers],
              businessCaseStageName:
                this.collaboratorsData().businessCaseStageName
            });
          }
        }
      });
  }

  updateStageOwnerFilter(event: MatSelectChange): void {
    this.availableStageOwnerIds = this.accounts
      .filter((account) => !(event.value as Array<number>).includes(account.id))
      .map((account) => account.id);
  }

  updateReviewerFilter(event: MatSelectChange): void {
    this.availableReviewerIds = this.accounts
      .filter((account) => account.id !== event.value)
      .map((account) => account.id);
  }
}
