import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { firstValueFrom } from 'rxjs';
import { BusinessCaseBuildingEquipmentResultModel } from '../models/asset-list-equipment';
import { IAssetListParams } from '../models/asset-list-params';

@Injectable({
    providedIn: 'root'
})
export class AssetListService {
    private baseUrl: string = environment.apiUrl;
    private httpClient: HttpClient = inject(HttpClient);
    private _mockEngineeringData = 'assets/dummy-data/engineering-table-mock.json'

    public async getAssetListEquipmentData(
        { id, page, size, searchTerm, filter }: IAssetListParams
    ): Promise<BusinessCaseBuildingEquipmentResultModel> {
        return firstValueFrom(this.httpClient.get<BusinessCaseBuildingEquipmentResultModel>(
            `${this.baseUrl}/business-cases/${id}/equipment/filter?page=${page}&size=${size}&searchTerm=${searchTerm}&filter=${filter}`))
    }

}
